import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs/';
import { NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router, ActivatedRoute } from '@angular/router';
import { Account } from '../models/account';

import { AjaxqueryService } from 'app/services';

import { DOCUMENT } from '@angular/common';

@Injectable()
export class AccountService {

    private account = new BehaviorSubject(new Account());
    data = this.account.asObservable();
    snackBarRef: any;

    constructor(
        private _snackBar: MatSnackBar,
        private _route: ActivatedRoute,
        private _router: Router,
        private _ajaxQuery: AjaxqueryService,
        @Inject(DOCUMENT) private _document: HTMLDocument
    ) { 
    }

    updatedAccountSelection(data: Account): any {
        this.account.next(data);
    }

    checkSessionToken(): any 
    {
        const promise = new Promise((resolve, reject) => {

            let urlParameterAccountName = '';
            const splitted = window.location.href.split('?');
            if (splitted.length > 1){
                const qParams = splitted[1].split('&');
                for (const qPar of qParams){
                    const keyValue = qPar.split('=');
                    if (keyValue[0] === 'a'){
                        urlParameterAccountName = keyValue[1];
                    }
                }
            }
            
            this._ajaxQuery.checkSessionToken(urlParameterAccountName)
            .subscribe(data => {
                if (data == null || (data != null && (data.SESSION == null || (data.SESSION != null && data.SESSION !== 'OK'))))
                {
                    // Non ho una session o un login valido...
                    this.snackBarRef = this._snackBar.open('Sessione non valida.', 'OK', {
                        duration: 2000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                    });
                    this.snackBarRef.afterDismissed().subscribe(() => {

                        const currUrl = this._router.url;
                        console.log('currUrl ' + currUrl);

                        this._router.navigate(['/login'], { queryParamsHandling: 'merge' });
                    });
                    this.snackBarRef.onAction().subscribe(() => {

                        const currUrl = this._router.url;
                        console.log('currUrl ' + currUrl);

                        this._router.navigate(['/login'], { queryParamsHandling: 'merge' });
                    });
                    console.log('finita chiamata checkSessionToken');
                    resolve(true);
                } else {
                    if (data != null && data.Account){
                        // Update observable
                        this.updatedAccountSelection(data.Account);

                        // AGGIORNA ICON IN HTML FILE
                        /*
                        const kAccount = data.Account.kaccount;
                        const icon = data.Account.icon;
                        if (icon != null && icon !== ''){
                            const iconNoExt = icon.replace('https://myarea.cloud/wa_images/account/' + kAccount + '/profile/O/', '').replace('.jpg', '');

                            const icoUrl = 'https://sellbylink.app/wa_images/account/' + kAccount + '/profile/O/' + iconNoExt + '.ico';
                            console.log('ICON PRESENT! --> ' + icoUrl);
                            this._document.getElementById('appFavicon').setAttribute('href', icoUrl);
                        }
                        */

                    }
                    console.log('finita chiamata checkSessionToken');
                    resolve(true);
                }
            });
        });

        return promise;
    }
}

@NgModule({
    providers: [ /* DONT ADD THE SERVICE HERE */ ]
})
export class AccountModule {
        static forRoot(): any {
            return {
                ngModule: AccountModule,
                providers: [ AccountService ]
            };
        }
}
