import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MailConfirmComponent } from 'app/main/loginGroup/mail-confirm/mail-confirm.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const routes = [
    {
        path     : 'mail-confirm',
        component: MailConfirmComponent
    }
];

@NgModule({
    declarations: [
        MailConfirmComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatIconModule,

        TranslateModule,
        FuseSharedModule,
        MatSnackBarModule
    ]
})
export class MailConfirmModule
{
  

}
