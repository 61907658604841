import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxChartsModule } from '@swimlane/ngx-charts';
 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { FuseSharedModule } from '@fuse/shared.module';
import { UsmaComponent } from 'app/main/usma/usma/usma.component';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { TranslateModule } from '@ngx-translate/core';

//import { MatTableExporterModule } from 'mat-table-exporter';
import { MatToolbarModule } from '@angular/material';


const routes = [
    {
        path     : 'usma',
        component: UsmaComponent
    }
];

@NgModule({
    declarations: [
        UsmaComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatIconModule,
        MatButtonModule,
        MatChipsModule,
        MatRippleModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTabsModule,
        MatToolbarModule,
        NgxChartsModule,
        // MatTableExporterModule,

        TranslateModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatDatepickerModule,
        MatMomentDateModule,
    ]
})
export class UsmaModule
{
}
