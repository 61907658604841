export const locale = {
    lang: 'it',
    data: {
        'COMMISSION': {
            'COMMISSION': 'Provvigione',
            'ORDER': 'Ordine',
            'ORDER_DETAILS': 'Dettaglio',
            'ORDER_PRODUCTS': 'Prodotti',

            'ORDER_CONNECTED': 'Ordine collegato',

            'COMMISSION_DATE_CONFIRM': 'Data Conferma',
            'ORDER_DATE': 'Data Ordine',
            'COMMISSION_AMOUNT': 'Importo Provvigione',

            'DELETE_ORDER': 'ELIMINA',
            'CLOSE_ORDER': 'CHIUDI',
            'ORDER_STATUS': 'Stato',
            'SUBTOTAL_AMT': 'SubTotale',
            'DISCOUNT_AMT': 'Sconto',
            'SHIPPING_AMT': 'Spedizione',
            'TOTAL_AMT': 'Totale',
            'NAME': 'Nome',
            'PHONE': 'Telefono',
            'CUSTOMER': 'Cliente',
            'PAYMENT': 'Pagamento',
            'SHIPPING_DATA': 'Dati Spedizione',
            'BILLING_DATA': 'Dati Fatturazione',

            'PAY_METHOD': 'Tipo',
            'PAY_AMOUNT': 'Importo',
            'PAY_DATE': 'Data',

            'OPEN_INCOME': 'VEDI INCASSO',
            'COUPON_CODE': 'Codice',
            'COUPON_DESCR': 'Testo',
            'COUPON_DISCOUNT': 'Sconto applicato',

            'LINE_TITLE': 'Prodotto',
            'LINE_QUANTITY': 'Quantità',
            'LINE_PRICE': 'Prezzo',

            'SET_CARRIER_TRACKING': 'IMPOSTA TRACKING',

            'STATE_PAID': 'PAGATO',
            'STATE_WAITING_PAYMENT': 'ABBANDONATO',
            'STATE_WAITING_PAY_CASH': 'NON PAGATO',
            'STATE_CLOSE': 'CHIUSO',
            'STATE_DELETED': 'ELIMINATO',
            
            'STATE_CONFIRMED': 'CONFERMATA',
            'STATE_NOT_CONFIRMED': 'NON CONFERMATA',


            'PAYMETHOD_CASH': 'Contanti',
            'PAYMETHOD_DEBITCARD': 'POS o Bancomat',
            'PAYMETHOD_BANKDRAFT': 'Bonifico Bancario',
            'PAYMETHOD_STRIPE': 'Stripe',
            'PAYMETHOD_PAYPAL': 'PayPal',

            'female': 'donna',
            'male': 'uomo',
            'unisex': 'unisex',

            'adult': 'Adulto',
            'all_ages': 'Tutte le età',
            'teen': 'Ragazzo',
            'kids': 'Bambino',
            'infant': 'Infante',
            'newborn': 'Neonato',

            'SIZE': 'Dimensione',
            'COLOR': 'Colore',
            'AGE_GROUP': 'Fascia d\'Età',
            'GENDER': 'Sesso',
            'CUSTOM_OPTION': 'Variante Libera',

            'SET_ORDER_PAID': 'PAGATO',
            'OPEN_CUSTOMER': 'VEDI CLIENTE',
            'ADDTOCALENDAR': 'AGGIUNGI A CALENDAR'
        }
    }
};
