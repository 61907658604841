import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';

import {OrderComponent } from 'app/main/order/order/order.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { HttpClientModule } from '@angular/common/http';

const routes = [
    {
        path     : 'order',
        component: OrderComponent
    }
];

@NgModule({
    declarations: [
        OrderComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatDividerModule,
        MatExpansionModule,
        MatTableModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTabsModule,
        
        HttpClientModule,

        NgxDropzoneModule,
        TranslateModule,
        FuseSharedModule,
    ],
    exports     : [
        OrderComponent
    ]
})
export class OrderModule
{
}
