import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AjaxqueryService } from '../../services';

import { fuseAnimations } from '@fuse/animations';
import { Attachment } from 'app/main/article/attachment.model';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadAttachmentDialogComponent } from './dialogs/uploadAttachment/uploadattachment.component';
import { ContactService } from 'app/services';
import { AccountService } from 'app/services';

declare var select: any;

@Component({
    selector   : 'article',
    templateUrl: './article.component.html',
    styleUrls  : ['./article.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ArticleComponent implements OnInit
{

    karticle: string;

    article: any;
    articleSorted: any;
    attachments: Attachment[];

    presentParentArticle: any;
    presentParentArticleSorted: any;

    possibleChildrenHeaders: any[];

    /**
     * Constructor
     */
    constructor(
        private http: HttpClient, 
        private _ajaxQuery: AjaxqueryService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _contactService: ContactService,
        private _accountService: AccountService,
        public _matDialog: MatDialog
    )
    {
        this.resetVars();

    }

    resetVars(): void
    {
        this.article = {};
        this.articleSorted = {};
        this.attachments = [];

        this.presentParentArticle = {};
        this.presentParentArticleSorted = {};
        this.possibleChildrenHeaders = [];
    }

    ngOnInit(): void
    {
        this._route.queryParams.subscribe(queryParams => {
            // console.log('parametri ' + JSON.stringify(queryParams)); // Your params called every change
            this.karticle = queryParams.ka;    
            this.resetVars();
            this.loadPage();     
        });
    }

    loadPage(): void
    {
        this._accountService.checkSessionToken();
        
        if (!this._contactService.isContactActive()){
            const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
            this._router.navigate(['/home'], { queryParams: { t: random }, queryParamsHandling: 'merge' });
        }

        this.karticle = this._route.snapshot.queryParams['ka'];
        
        this._ajaxQuery.getArticleDetails(this.karticle)
        .subscribe(data => {

            // Imposto comunque i parametri per la visibilità dell'account
            if (data != null && data.Article != null){
                this.article = data.Article;
                this.articleSorted = this.getArticleSortingOrder(this.article);
                // console.log('articleSorted ' + JSON.stringify(this.articleSorted));
            }

            if (data != null && data.PresentParentArticle != null){
                this.presentParentArticle = data.PresentParentArticle;
                this.presentParentArticleSorted = this.getArticleSortingOrder(this.presentParentArticle);
                // console.log('this.presentParentArticleSorted ' + JSON.stringify(this.presentParentArticleSorted));
            }

            if (data != null && data.PossibleChildrenHeaders != null && data.PossibleChildrenHeaders.length > 0){

                for (let i = 0; i<data.PossibleChildrenHeaders.length; i++){
                    const childHeadRec = data.PossibleChildrenHeaders[i];

                    if (data.PresentChildrenArticles != null && data.PresentChildrenArticles.length > 0){
                        // Cerco eventuali figli presenti
                        const recs = select(data.PresentChildrenArticles).where('FK_ArticleHeader').is(childHeadRec.K_ArticleHeader).end();
                        if (recs.length > 0){
                            const childz = [];

                            for (let j = 0; j < recs.length; j++){
                                const art = recs[j];
                                const arts = this.getArticleSortingOrder(art);
                                arts.unshift({K_Article: art.K_Article});
                                childz.push(arts);
                            }

                            childHeadRec['presentChilds'] = childz;
                        }
                    }  
                    this.possibleChildrenHeaders.push(childHeadRec);
                }
                
                // console.log('this.possibleChildrenHeaders ' + JSON.stringify(this.possibleChildrenHeaders));
            }

            if (data != null && data.Attachments != null){
                this.attachments = data.Attachments;

                /*
                for (let i = 0; i < data.Attachments.length; i++){
                    const rec = data.Attachments[i];
                    fetch(data.Attachments[i].url)
                    .then(response => response.blob())
                    .then(blob => {

                        const downloadSrc = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));


                    });
                }
                */
                // console.log('Attachs ' + JSON.stringify(this.attachments));
            }
        });
        

    }


    getArticleSortingOrder(articRec: any): any
    {
        const sortRecords = [];
        for (let i = 1; i < 102; i++){
            let j = '' + i;
            if (i < 10){
                j = '0' + i;
            }

            const columnSorter = {};
            
            let sortOrder = articRec['Sorting_' + j];
            if (sortOrder == null || sortOrder === 'null' || sortOrder === ''){
                sortOrder = 1000 + i;
            }
            columnSorter['SortOrder'] = parseInt(sortOrder, 10);
            
            let prefix = 'String';
            columnSorter['Value_Form'] = '';
            if (articRec[prefix + 'Value_' + j] != null && articRec[prefix + 'Value_' + j] !== 'null'){
                columnSorter['Value_Form'] = articRec[prefix + 'Value_' + j];
            }
            
            if (i > 10 && i <= 15){
                prefix = 'Integer';
                if (articRec[prefix + 'Value_' + j] != null){
                    columnSorter['Value_Form'] = parseInt(articRec[prefix + 'Value_' + j], 10);
                }
            } else if (i > 15 && i <= 20){
                prefix = 'Date';
                if (articRec[prefix + 'Value_' + j] != null){
                    const dtle = this.parseDateTime(articRec[prefix + 'Value_' + j + '_Form'], 'SERVER', articRec.ServerMinutesTimeZoneOffset);
                    columnSorter['Value_Form'] = this.formatDate(dtle, 'dd/MM/yyyy');
                }
            } else if (i > 20 && i <= 23){
                prefix = 'BigDec';
                if (articRec[prefix + 'Value_' + j] != null){
                    columnSorter['Value_Form'] = articRec[prefix + 'Value_' + j];
                }
            } else if (i > 23 && i <= 25){
                prefix = 'Bit';
                if (articRec[prefix + 'Value_' + j] != null && articRec[prefix + 'Value_' + j] === '0'){
                    columnSorter['Value_Form'] = 'NO';
                } else if (articRec[prefix + 'Value_' + j] != null && articRec[prefix + 'Value_' + j] === '1'){
                    columnSorter['Value_Form'] = 'SI';
                }
            } else if (i === 26){
                prefix = 'Text';
                if (articRec[prefix + 'Value_' + j] != null && articRec[prefix + 'Value_' + j] !== 'null'){
                    columnSorter['Value_Form'] = articRec[prefix + 'Value_' + j];
                }
            } else if (i === 100){
                prefix = 'Date';
                if (articRec[prefix + 'Value_' + j] != null){
                    const dtle = this.parseDateTime(articRec[prefix + 'Value_' + j + '_Form'], 'SERVER', articRec.ServerMinutesTimeZoneOffset);
                    columnSorter['Value_Form'] = this.formatDate(dtle, 'dd/MM/yyyy');
                }
            } else if (i === 101){
                // Salto questo BIT 
                prefix = 'Bit';
                if (articRec[prefix + 'Value_' + j] != null && articRec[prefix + 'Value_' + j] === '0'){
                    columnSorter['Value_Form'] = 'NO';
                } else if (articRec[prefix + 'Value_' + j] != null && articRec[prefix + 'Value_' + j] === '1'){
                    columnSorter['Value_Form'] = 'SI';
                }
            }
            
            columnSorter['Type'] = prefix;
            columnSorter['Counter'] = j;
            columnSorter['Label'] = articRec[prefix + 'Label_' + j];
            columnSorter['Value'] = articRec[prefix + 'Value_' + j];
            
            sortRecords.push(columnSorter);
        }
    
        const columnOrder = select(sortRecords).sort('SortOrder').asc().end();
        
        return columnOrder;
    }


    parseDateTime(stringDate: string, OWNER: string, serverMinutesTimeZoneOffset: number): any 
    {
        if (stringDate == null || stringDate === ''){
            return null;
        }

        const year = parseInt(stringDate.substring(0, 4), 10);    
        const month = parseInt(stringDate.substring(5, 7), 10) - 1;
        const day = parseInt(stringDate.substring(8, 10), 10);
        const hours = parseInt(stringDate.substring(11, 13), 10);
        const minutes = parseInt(stringDate.substring(14, 16), 10);
        const seconds = parseInt(stringDate.substring(17, 19), 10);

        let date = new Date(year, month, day, hours, minutes, seconds);

        if (OWNER === 'SERVER'){
            if (serverMinutesTimeZoneOffset == null){
                console.error('ERROR serverMinutesTimeZoneOffset NULL');
            }
            
            date = new Date(date.getTime() + (serverMinutesTimeZoneOffset * 60 * 1000));
        }
        
        return date;
    }


    formatDate(date: any, format: string): string
    {
        const o = {
            'M+' : date.getMonth() + 1, // month
            'd+' : date.getDate(),    // day
            'h+' : date.getHours(),   // hour
            'm+' : date.getMinutes(), // minute
            's+' : date.getSeconds(), // second
            'q+' : Math.floor((date.getMonth() + 3) / 3),  // quarter
            'S' : date.getMilliseconds() // millisecond
        };

        if (/(y+)/.test(format)){
            format = format.replace(RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k in o){
            if (new RegExp('(' + k + ')').test(format)){
                format = format.replace(RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
            }
        }
        return format;
    }
    
    openAttachment(url: string): void
    {
        window.open(url, '_blank');   
    }

    downloadAttachment(url: string, fileName: string): void
    {
        this.http.get(url, { responseType: 'blob', observe: 'response' })
            .subscribe(respone => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(respone.body);
                link.download = fileName;
                link.click();
            });
    }

    openArticle(kArticle: string): void
    {
        this._router.navigate(['/article'], { queryParams: { ka: kArticle }, queryParamsHandling: 'merge' });
    }

    uploadAttachment(kArticle: string): void
    {
        
        const dialogRef = this._matDialog.open(UploadAttachmentDialogComponent, {
            panelClass: 'upload-attachment-dialog',
            // width: '250px',
            data: { article: this.article,  articleSorted: this.articleSorted }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            // this.password = result;
        });

    }



}

