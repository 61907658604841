import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from 'app/services';
import { Account } from 'app/models';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

import { fuseAnimations } from '@fuse/animations';
import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
    selector     : 'commission',
    templateUrl  : './commission.component.html',
    styleUrls    : ['./commission.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class CommissionComponent implements OnInit, OnDestroy
{
    kCommission: string;
    commission: any;
    order: any;

    account: Account;

    confirmed: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationService: TranslateService,
        private _ajaxQueryService: AjaxqueryService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _matDialog: MatDialog,
        private _accountService: AccountService,
        private datePipe: DatePipe,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.commission = {};
        this.account = new Account();
        this.order = {};

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._fuseTranslationLoaderService.loadTranslations(english, italian);
        this._fuseTranslationService.use(this._fuseTranslationService.currentLang);

        this.confirmed = 'NOT_CONFIRMED';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._route.queryParams.subscribe(queryParams => {
            this.kCommission = queryParams.kco;    
            this.resetVars();
            this.loadPage();     
        });

    }

    resetVars(): void
    {
        this.kCommission = '';
        this.commission = {};
        this.order = {};
    }

    loadPage(): void
    {
        this.account = new Account();
        this._accountService.data
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            // do what ever needs doing when data changes
            this.account = data;
        });

        
        this.kCommission = this._route.snapshot.queryParams['kco'];
        this._ajaxQueryService.getCommissionDetails(this.kCommission)
        .subscribe(data => {

            if (data != null && data.Commission != null){
                this.commission = data.Commission;

                if (this.commission.CommissionState === 'CONFIRMED'){
                    this.confirmed = 'CONFIRMED';
                } else {
                    this.confirmed = 'NOT_CONFIRMED';
                }

                

            }

            if (data != null && data.Order != null){
                this.order = data.Order;
            }

        });

    }



    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    goBackPage(): any
    {
        const yyyymm = this._route.snapshot.queryParams['yyyymm'];
        if (yyyymm != null && yyyymm !== ''){
            this._router.navigate(['/commissions'], { queryParams: { yyyymm: yyyymm }, queryParamsHandling: 'merge' });
        } else {
            this._router.navigate(['/home']);
        }

    }


}
