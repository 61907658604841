import { Component, Inject, ViewEncapsulation, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector     : 'chose-password-dialog',
    templateUrl  : './chosepassword.component.html',
    styleUrls    : ['./chosepassword.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChosePasswordDialogComponent
{
    formTitle: string;
    chosePasswordForm: FormGroup;
    email: string;

    snackBarRef: any;

    hide: boolean;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ChosePasswordDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<ChosePasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _ajaxQuery: AjaxqueryService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _route: ActivatedRoute
    )
    { 
        if (_data != null && _data.email != null){
            this.email = _data.email;
            this.formTitle = 'Scegli una password';
        } else {
            this.formTitle = 'Scegli email e password';
        }

        // Set the defaults
        this.chosePasswordForm = this.createChosePasswordForm();
        this.hide = true;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createChosePasswordForm(): FormGroup
    {

        let emailForm = new FormControl('', Validators.required);
        if (this.email != null && this.email !== ''){
            emailForm = new FormControl({value: this.email, disabled: true}, Validators.required);
        }

        const pwdForm = new FormControl('', Validators.required);
        this.hide = true;

        return new FormGroup({
            /*from   : new FormControl({
                value   : 'johndoe@creapond.com',
                disabled: true
            }),
            to     : new FormControl(''),
            cc     : new FormControl(''),
            bcc    : new FormControl(''),
            subject: new FormControl(''),*/
            email: emailForm,
            password: pwdForm,
            passwordConfirm: new FormControl('', [Validators.required, confirmPasswordValidator])

        });

        
    }

   
    sendPasswordServer(): void
    {
        const email = this.chosePasswordForm.getRawValue().email;
        const password = this.chosePasswordForm.getRawValue().password;
        const passwordConfirm = this.chosePasswordForm.getRawValue().passwordConfirm;
        
        // console.log('sendPasswordServer ' + email + ' ' + password + ' ' + passwordConfirm);

        if (password != null && passwordConfirm != null && password === passwordConfirm && email != null){
            this._ajaxQuery.addPasswordToContact(email, password)
                .subscribe(data => {
                    if (data != null && data.OK === 'OK'){

                        this.matDialogRef.close(['send', this.chosePasswordForm]);
                        this.snackBarRef = this._snackBar.open('Abbiamo inviato la mail a ' + data.email + '. Controlla la tua inbox e premi il link di conferma.', 'OK', {
                            duration: 7000,
                            horizontalPosition: 'center',
                            panelClass: ['blue-snackbar'],
                            verticalPosition: 'top'
                        });

                        this.snackBarRef.afterDismissed().subscribe(() => {
                            this.goOrReloadHome();
                        });

                        this.snackBarRef.onAction().subscribe(() => {
                            this.goOrReloadHome();
                        });


                    } else {
                        this._snackBar.open('Si è verificato un errore in fase di aggiornamento!', 'OK', {
                            duration: 4000,
                            horizontalPosition: 'center',
                            panelClass: ['red-snackbar'],
                            verticalPosition: 'top'
                        });
                    }
                });
        }
    }

    goOrReloadHome(): any
    {
        const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
        this._router.navigate(['/home'], { queryParams: { t: random }, queryParamsHandling: 'merge' });
    }
    
}


/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};

