export const locale = {
    lang: 'it',
    data: {
        'ORDERS': {
            'SUPPLIER_ORDERS': 'Ordini Fornitore',
            'ORDER_NUMBER': 'Numero',
            'ORDER_DATE': 'Data',
            'TOTAL': 'Importo',
            'NOTE': 'Note',
            'STATE': 'Stato',
            'CUSTOMER': 'Cliente',

            'STATE_PAID': 'PAGATO',
            'STATE_WAITING_PAYMENT': 'ABBANDONATO',
            'STATE_WAITING_PAY_CASH': 'NON PAGATO',
            'STATE_CLOSE': 'CHIUSO',
            'STATE_DELETED': 'ELIMINATO',

            'STATE_SUPPLIER_DELIVERED_BACK': 'SPEDITO DA FORNITORE',
            'STATE_SUPPLIER_SENT': 'IN CORSO',
            'STATE_SUPPLIER_RECEIVED': 'CHIUSO',

            'ADDTOCALENDAR': 'AGGIUNGI A CALENDAR'
        }
    }
};
