import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, catchError, finalize, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';

import { AccountService } from 'app/services';
import { Account } from 'app/models';

import { takeUntil } from 'rxjs/internal/operators';

import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { formatDate, DatePipe } from '@angular/common';
import { UsmaDetailsDialogComponent } from './dialogs/usmadetailsdialog.component';
import { MatDialog } from '@angular/material';


@Component({
    selector     : 'usma',
    templateUrl  : './usma.component.html',
    styleUrls    : ['./usma.component.scss'],
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UsmaComponent implements OnInit, AfterViewInit
{
    dataSource: UsmaDataSource | null;
    displayedColumns = ['Mese', 'Num.Pranzi', 'Costo.Pranzi', 'Num.Merende', 'Costo.Merende', 'Arrotondamento', 'Dovuto', 'Pagato', 'Aperto'];

    account: Account;

    anni_scolastici: any[];
    anno_scolastico: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationService: TranslateService,
        private _accountService: AccountService,
        private _datePipe: DatePipe,
        private _ajaxQueryService: AjaxqueryService,
        public _matDialog: MatDialog,
        private _router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        
        // this._fuseTranslationService.use(this._fuseTranslationService.currentLang);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new UsmaDataSource(this._ajaxQueryService, this._datePipe);
        
        
        const now = new Date();

        const month = parseInt(formatDate(now, "MM", 'en-GB'));
        const year = parseInt(formatDate(now, "yyyy", 'en-GB'));

        this.anni_scolastici = [
            '2019-2020',
            '2020-2021',
            '2021-2022',
            '2022-2023',
            '2023-2024',
            '2024-2025',
            '2025-2026',
            '2026-2027',
            '2027-2028',
        ];

        if (month <= 8){
            this.anno_scolastico = ''+(year-1)+'-'+year;
        } else {
            this.anno_scolastico = ''+year+'-'+(year+1);
        }

        this.dataSource.loadUsma(this.anno_scolastico);

        this.account = new Account();
        this._accountService.data
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            // do what ever needs doing when data changes
            this.account = data;
            
        });
    }

    ngAfterViewInit(): void 
    {       

    }

    openOrder(kOrderHeader: string): any
    {
        this._router.navigate(['/detailsorder'], { queryParams: { koh: kOrderHeader }, queryParamsHandling: 'merge' });
    }

    loadUsmaPage(): any
    {
        this.dataSource.loadUsma(this.anno_scolastico);
    }

    onChangeAnnoScolastico($event): any
    {
        this.anno_scolastico = $event.value;

        this.loadUsmaPage();
    }

    openUsmaDetails(usma_record: any)
    {
        const dialogRef = this._matDialog.open(UsmaDetailsDialogComponent, {
            panelClass: 'usma-details-dialog',
            width: '320px',
            data: { 
                title: 'Dettaglio', 
                usma_record: usma_record,
                cancelText: 'Chiudi'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }


}



export class UsmaDataSource implements DataSource<any> {

    private usmaSubject = new BehaviorSubject<any[]>([]);
    private loadingUsma = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingUsma.asObservable();

    constructor(
        private _ajaxQueryService: AjaxqueryService,
        private datePipe: DatePipe
    ) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.usmaSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.usmaSubject.complete();
    }

    loadUsma(targetDate: string ): any
    {

        this.loadingUsma.next(true);
        this._ajaxQueryService.getUsmaRecords(targetDate)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingUsma.next(false))
        )
        .subscribe(data => {
            if (data != null && data.UsmaRecords != null && data.UsmaRecords.length > 0){
                
                // elaboro la data ordine per formattarla x client (TimeZoneOffset)
                const ordrs = [];
                for (const order of data.UsmaRecords) {
                    // order.DT_OrderDate_Form_date = this._utilService.parseDateTime(order.DT_OrderDate_Form, 'SERVER', order.ServerMinutesTimeZoneOffset);
                    if (order.ImportoPagato == null || order.ImportoPagato == ''){
                        order.ImportoPagato = 0;
                    }
                    if (order.Arrotondamento == null || order.Arrotondamento == ''){
                        order.Arrotondamento = 0;
                    }

                    // order['ImportoAperto'] = order.CostoTotale - order.ImportoPagato;
                    ordrs.push(order);
                }

                this.usmaSubject.next(ordrs);
            } else {
                this.usmaSubject.next([]);
            }
            const element = document.querySelector('#container-3') || window;
            element.scrollTo(0, 0);
 
        });
    }    
}


