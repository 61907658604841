import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AjaxqueryService } from '../../services';

import { fuseAnimations } from '@fuse/animations';
import { Document } from 'app/main/documents/document.model';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactService } from 'app/services';
import { AccountService } from 'app/services';

declare var select: any;

@Component({
    selector   : 'documents',
    templateUrl: './documents.component.html',
    styleUrls  : ['./documents.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class DocumentsComponent implements OnInit
{

    documents: Document[];

    /**
     * Constructor
     */
    constructor(
        private http: HttpClient, 
        private _ajaxQuery: AjaxqueryService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _contactService: ContactService,
        private _accountService: AccountService,
        public _matDialog: MatDialog
    )
    {

    }

    ngOnInit(): void
    {
        this._accountService.checkSessionToken();

        if (!this._contactService.isContactActive()){
            const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
            this._router.navigate(['/home'], { queryParams: { t: random }, queryParamsHandling: 'merge' });
        }

        this._ajaxQuery.getDocuments()
        .subscribe(data => {

            // Imposto comunque i parametri per la visibilità dell'account
            if (data != null && data.Documents != null){
                this.documents = data.Documents.map(document => {
                    return new Document(document);
                });
            }

        });

    }



    parseDateTime(stringDate: string, OWNER: string, serverMinutesTimeZoneOffset: number): any 
    {
        if (stringDate == null || stringDate === ''){
            return null;
        }

        const year = parseInt(stringDate.substring(0, 4), 10);    
        const month = parseInt(stringDate.substring(5, 7), 10) - 1;
        const day = parseInt(stringDate.substring(8, 10), 10);
        const hours = parseInt(stringDate.substring(11, 13), 10);
        const minutes = parseInt(stringDate.substring(14, 16), 10);
        const seconds = parseInt(stringDate.substring(17, 19), 10);

        let date = new Date(year, month, day, hours, minutes, seconds);

        if (OWNER === 'SERVER'){
            if (serverMinutesTimeZoneOffset == null){
                console.error('ERROR serverMinutesTimeZoneOffset NULL');
            }
            
            date = new Date(date.getTime() + (serverMinutesTimeZoneOffset * 60 * 1000));
        }
        
        return date;
    }


    formatDate(date: any, format: string): string
    {
        const o = {
            'M+' : date.getMonth() + 1, // month
            'd+' : date.getDate(),    // day
            'h+' : date.getHours(),   // hour
            'm+' : date.getMinutes(), // minute
            's+' : date.getSeconds(), // second
            'q+' : Math.floor((date.getMonth() + 3) / 3),  // quarter
            'S' : date.getMilliseconds() // millisecond
        };

        if (/(y+)/.test(format)){
            format = format.replace(RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k in o){
            if (new RegExp('(' + k + ')').test(format)){
                format = format.replace(RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
            }
        }
        return format;
    }
    
    openAttachment(url: string): void
    {
        window.open(url, '_blank');   
    }

    downloadAttachment(url: string, fileName: string): void
    {
        this.http.get(url, { responseType: 'blob', observe: 'response' })
            .subscribe(respone => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(respone.body);
                link.download = fileName;
                link.click();
            });
    }


}

