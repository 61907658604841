export const locale = {
    lang: 'it',
    data: {
        'HOME': {
            
            'ORDERS_TITLE': 'Ordini',
            'NO_ORDERS_TO_PROCESS': 'Nessun Ordine',
            'ORDER': 'Ordine',
            'LIST': 'ELENCO',
            
            'ADDTOCALENDAR': 'AGGIUNGI A CALENDAR'
        }
    }
};
