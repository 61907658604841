import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../../services';

import { AccountService } from 'app/services';
import { Account } from 'app/models';

@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit
{
    forgotPasswordForm: FormGroup;

    urlParameterAccountName: string;

    emailToReset: string;
    snackBarRef: any;

    account: Account;

    buttonLoading: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService,
        private _accountService: AccountService,
        private _snackBar: MatSnackBar
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._unsubscribeAll = new Subject();
        this.buttonLoading = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.urlParameterAccountName = this.route.snapshot.queryParams['a'] || '';

        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
        
        if (this.urlParameterAccountName != null && this.urlParameterAccountName !== ''){
            this._loginService.getAccountData(this.urlParameterAccountName)
                .subscribe(data => {
                    // Imposto comunque i parametri per la visibilità dell'account
                    if (data != null && data.Account){
                        // Update observable
                        this._accountService.updatedAccountSelection(data.Account);
                    }
                });
        }

        this._accountService.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                // do what ever needs doing when data changes
                this.account = data;            
            });

    }

    goToLogin(): void
    {
        this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
    }

    onSubmit(): void 
    {
        
        console.log('onSubmit');

        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }
        this.buttonLoading = true;

        this.emailToReset = this.forgotPasswordForm.controls.email.value.trim();
        
        let accountName = this.urlParameterAccountName;
        if (accountName == null || accountName === ''){
            accountName = this.account.publicname;
        }

        this._loginService.forgotPassword(this.emailToReset, accountName)
            .subscribe(data => {
                this.buttonLoading = false;
                console.log(JSON.stringify(data));
                if (data != null && data.OK != null && data.OK === 'OK'){
                    this.snackBarRef = this._snackBar.open('Abbiamo inviato una mail con il link per reimpostare la password!', 'OK', {
                        duration: 10000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });

                    this.snackBarRef.afterDismissed().subscribe(() => {
                        this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
                    });
        
                    this.snackBarRef.onAction().subscribe(() => {
                        this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
                    });

                } else {
                    this._snackBar.open('Si è verificato un errore in fase di richiesta di reset password', 'OK', {
                        duration: 4000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });

                }

            });

    }


}
