import { Component, Inject, ViewEncapsulation, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector     : 'upload-attachment-dialog',
    templateUrl  : './uploadattachment.component.html',
    styleUrls    : ['./uploadattachment.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UploadAttachmentDialogComponent
{
    formTitle: string;
    uploadAttachmentForm: FormGroup;
    article: any;
    articleSorted: any;

    snackBarRef: any;

    files: File[] = [];

    buttonLoading: boolean;

    /**
     * Constructor
     *
     * @param {MatDialogRef<UploadAttachmentDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<UploadAttachmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _ajaxQuery: AjaxqueryService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _route: ActivatedRoute
    )
    {
        if (_data != null && _data.article != null){
            this.article = _data.article;
            this.articleSorted = _data.articleSorted;
            // this.formTitle = 'Aggiungi allegato per ' + this.article.ArticleName + ' ' + this.articleSorted[0].Value_Form + ' ' +this.articleSorted[1].Value_Form;
            this.formTitle = 'Aggiungi allegato';
        } else {
            this.formTitle = 'Aggiungi allegato';
        }

        // Set the defaults
        this.uploadAttachmentForm = this.createForm();
        this.buttonLoading = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createForm(): FormGroup
    {

        let emailForm = new FormControl('', Validators.required);
        
        const pwdForm = new FormControl('', Validators.required);
       

        return new FormGroup({
            /*from   : new FormControl({
                value   : 'johndoe@creapond.com',
                disabled: true
            }),
            to     : new FormControl(''),
            cc     : new FormControl(''),
            bcc    : new FormControl(''),
            subject: new FormControl(''),
            email: emailForm,
            password: pwdForm,
            passwordConfirm: new FormControl('', [Validators.required, confirmPasswordValidator])*/

        });

        
    }

   
    uploadAttachment(): void
    {
        let fileToSend = null;
        if (this.files != null && this.files.length > 0){
            this.buttonLoading = true;
            fileToSend = this.files[0];

            this._ajaxQuery.uploadAttachment(this.article.K_Article, fileToSend)
                .subscribe(data => {
                    this.buttonLoading = false;
                    if (data != null && data.OK === 'OK'){
                        this.matDialogRef.close(['send', this.uploadAttachmentForm]);
                        this._snackBar.open('Nuovo file caricato correttamente', 'OK', {
                            duration: 4000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });
                        this.goOrReloadArticle();
                    } else {
                        this._snackBar.open('Si è verificato un errore. Per favore prova nuovamente.', 'OK', {
                            duration: 4000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });
                    }
                });

        } else {
            // fai nulla.... TOAST?
        }
    }

    goOrReloadArticle(): any
    {
        const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
        this._router.navigate(['/article'], { queryParams: { t: random }, queryParamsHandling: 'merge' });
    }
    

    /*
     * DROPZONE METHODS
     */
    onSelect(event: any): void {
        console.log(event);
        if (this.files.length > 0){
            alert('Puoi allegare solo un file per ogni messaggio');
        } else {
            this.files.push(...event.addedFiles);
        }
    }

    onRemove(event: any): void {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);    
    }

    onFilesRejected(event: any): void {
        this._snackBar.open('Si è verificato un errore, probabilemte il file è troppo grande o non supportato.', 'OK', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
        });
    }

}
