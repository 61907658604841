export const locale = {
    lang: 'en',
    data: {
        'ORDERS': {
            'SUPPLIER_ORDERS': 'Supplier Orders',
            'ORDER_NUMBER': 'Number',
            'ORDER_DATE': 'Date',
            'TOTAL': 'Total',
            'NOTE': 'Note',
            'STATE': 'State',
            'CUSTOMER': 'Customer',

            'STATE_PAID': 'PAID',
            'STATE_WAITING_PAYMENT': 'ABANDONED CART',
            'STATE_WAITING_PAY_CASH': 'NOT PAID',
            'STATE_CLOSE': 'CLOSED',
            'STATE_DELETED': 'DELETED',

            'STATE_SUPPLIER_DELIVERED_BACK': 'SENT BACK',
            'STATE_SUPPLIER_SENT': 'IN PROCESS',
            'STATE_SUPPLIER_RECEIVED': 'CLOSED',

            'ADDTOCALENDAR': 'ADD TO CALENDAR'
        }
    }
};
