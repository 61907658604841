import { Component, Inject, ViewEncapsulation, Directive, HostListener } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector     : 'usma-details-dialog',
    templateUrl  : './usmadetailsdialog.component.html',
    styleUrls    : ['./usmadetailsdialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UsmaDetailsDialogComponent
{
    
    data: any;

    snackBarRef: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<UsmaDetailsDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<UsmaDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _ajaxQuery: AjaxqueryService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _route: ActivatedRoute
    )
    { 

        this.data = _data;

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public cancel(): any
    {
        this.close(false);
    }
    public close(value: boolean): any 
    {
        this.matDialogRef.close(value);
    }
    public confirm(): any
    {
        this.close(true);
    }
    
    @HostListener('keydown.esc') 
    public onEsc(): any 
    {
        this.close(false);
    }


    
}

