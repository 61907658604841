export const locale = {
    lang: 'it',
    data: {
        'LOGIN': {
            'ACCOUNT': 'Accedi alla tua area',
            'EMAILORPHONE': 'Email',
            'EMAILREQUIRED': 'Per favore inserisci una Email',
            'SENDCODE': 'Inviami il codice *'
        }
    }
};
