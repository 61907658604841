import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { LoginService } from '../../services';
import { AjaxqueryService } from '../../services';

import { Router, ActivatedRoute } from '@angular/router';

import { fuseAnimations } from '@fuse/animations';

import { DomSanitizer } from '@angular/platform-browser';

import { ContactService } from 'app/services';
import { Contact } from 'app/models';

import { AccountService } from 'app/services';
import { Account } from 'app/models';

declare var select: any;

@Component({
    selector   : 'shop',
    templateUrl: './shop.component.html',
    styleUrls  : ['./shop.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ShopComponent implements OnInit
{
    card19: any;
    rootHeaders: any;
    bookings: any;
    reminders: any;
    products: any;
    messages: any;
    unreadMessages: any;
    private urlImage: string;
    dialogRef: any;

    contact: Contact;
    account: Account;

    urlToOpen;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _loginService: LoginService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationService: TranslateService,
        private _ajaxQuery: AjaxqueryService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _contactService: ContactService,
        private _accountService: AccountService,
        private sanitizer: DomSanitizer
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, italian);
        
        this.card19 = {
            scheme: {
                domain: ['#5c84f1']
            }
        };

        this._fuseTranslationService.use('it');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }


    ngOnInit(): void
    {
        
        this.contact = null;
        this._contactService.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                // do what ever needs doing when data changes
                this.contact = data;            
                this.composeUrlToOpen();
            });

        this.account = null;
        this._accountService.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                // do what ever needs doing when data changes
                this.account = data; 
                
                if (this.account == null || this.account.kaccount == null){
                    this._router.navigate(['/home'], { queryParamsHandling: 'merge' });
                }
                this.composeUrlToOpen();
            });
    

    }

    composeUrlToOpen(): void
    {

        if (this.account != null && this.contact != null && this.contact.master != null){
            
            const publicname = this.account.publicname;
            const kCtc = this.contact.master.kcontact;
            if (kCtc == null){
                alert('Utente non loggato');
                return null;
            }

            this._ajaxQuery.checkSessionToken(publicname)
            .subscribe(data => {
                
                if (data != null && data.SS != null){
                    
                    const params = 'kCo=' + kCtc + '&kSe=' + data.SS + '&kFr=mya';
                    this.urlToOpen = this.sanitizer.bypassSecurityTrustResourceUrl('https://sellbylink.app/s/' + publicname + '?' + params);
                }
            });
        }
    }

    openBuyProduct(kProduct): void
    {
        alert(kProduct);

        
    }

}
