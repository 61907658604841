import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';

import { CommonModule } from '@angular/common';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';

import { ArticleComponent } from 'app/main/article/article.component';

import { FuseSidebarModule } from '@fuse/components';

import { UploadAttachmentDialogComponent } from './dialogs/uploadAttachment/uploadattachment.component';
import { NgxDropzoneModule } from 'ngx-dropzone';

const routes = [
    // Carded
    {
        path     : 'article',
        component: ArticleComponent
    }
];

@NgModule({
    declarations: [
        ArticleComponent,
        UploadAttachmentDialogComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        MatExpansionModule,
        MatToolbarModule,
        MatProgressSpinnerModule,

        FuseSidebarModule,
        FuseSharedModule,
        FuseDemoModule,
        NgxDropzoneModule
    ],
    exports     : [
        ArticleComponent,
        UploadAttachmentDialogComponent
    ],
    entryComponents: [
        UploadAttachmentDialogComponent
    ]
})
export class ArticleModule
{
}
