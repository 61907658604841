import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { Router, ActivatedRoute } from '@angular/router';

import { LoginService } from '../../../services';

@Component({
    selector     : 'mail-confirm',
    templateUrl  : './account-confirm.component.html',
    styleUrls    : ['./account-confirm.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class AccountConfirmComponent implements OnInit
{

    email: string;
    acivationCode: string;
    accountPName: string;

    icon: string;
    title: string;
    description: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private _loginService: LoginService,
        private route: ActivatedRoute
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }

    ngOnInit(): void
    {
        this.email = this.route.snapshot.queryParams['e'] || '';
        this.accountPName = this.route.snapshot.queryParams['a'] || '';
        this.acivationCode = this.route.snapshot.queryParams['ac'] || '';

        this.icon = 'thumb_down';
        this.title = 'Si è verificato un errore in fase di attivazione dell\'account';

        if (this.email == null || this.email === ''){
            this.email = 'specificato in fase di registrazione';
        }

        this._loginService.activateAccount(this.acivationCode, this.email)
            .subscribe(data => {
                console.log(JSON.stringify(data));
                if (data != null && data.OK != null && data.OK === 'OK'){
                    this.icon = 'thumb_up';
                    this.title = 'Complimenti! Il tuo account è confermato!';
                    this.description = 'Il tuo account collegato all\'email ' + this.email + ' è stato attivato correttamente!.\n\n' +
                                            'Accedi dalla pagina di login per poter vedere tutti i tuoi dati.';
                } else {
                    this.icon = 'thumb_down';
                    this.title = 'Si è verificato un errore in fase di attivazione dell\'account';
                    this.description = 'Il tuo account collegato all\'email ' + this.email + ' non risulta verificato.\n\n' +
                                            'Per favore prova ad accedere nuovamente.';
                }

            });

    }

    goToLogin(): void
    {
        this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
    }

}
