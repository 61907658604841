export class Account {
    kaccount: string;
    name: string;
    publicname: string;
    image: string;
    icon: string;
    logo: string;
    email: string;
    hexcolor: string;
    phone: string;

    hideBacheca: string;
    hideShop: string;
    hasMondoScuola: string;

    vatid: string;
    fiscalname: string;
    address: string;
    cap: string;
    city: string;
    country: string;
    province: string;
    emailinvoice: string;
    companyphone: string;
    
}
