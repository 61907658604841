import { Component, Inject, ViewEncapsulation, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector     : 'export-event-dialog',
    templateUrl  : './exportevent.component.html',
    styleUrls    : ['./exportevent.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ExportEventDialogComponent
{
    formTitle: string;
    exportEventForm: FormGroup;
    title: string;
    dateFrom: string;
    dateTo: string;

    snackBarRef: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ExportEventDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<ExportEventDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _ajaxQuery: AjaxqueryService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _route: ActivatedRoute
    )
    { 
        this.formTitle = 'Salva nel calendario';
        if (_data != null && _data.title != null){
            this.title = _data.title;
        } 
        if (_data != null && _data.dateFrom != null){
            this.dateFrom = _data.dateFrom;
        }
        if (_data != null && _data.dateTo != null){
            this.dateTo = _data.dateTo;
        }

        // Set the defaults
        this.exportEventForm = this.createExportEventForm();
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createExportEventForm(): FormGroup
    {

        return new FormGroup({
           
        });
        
    }

    exportGoogle(): void
    {
        const lnnk = 'https://www.google.com/calendar/render?action=TEMPLATE&text=' + this.title +
                        '&dates=' + this.dateFrom + '/' + this.dateTo + '&sf=true&output=xml';
        window.open(lnnk, '_blank');
    }

    exportIcal(): void
    {
        this.title = this.title.replace(/'/g, '');
        const strIcal = 'BEGIN:VCALENDAR\n' +
                        'VERSION:2.0\n' +
                        'BEGIN:VEVENT\n' +
                        'DTSTAMP:20200204T081649Z\n' +
                        'DTSTART;TZID=Europe/Rome:' + this.dateFrom + '\n' +
                        'DTEND;TZID=Europe/Rome:' + this.dateTo + '\n' +
                        'SUMMARY: ' + this.title + '\n' +
                        'END:VEVENT\n' +
                        'END:VCALENDAR';
     
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(strIcal));
        element.setAttribute('download', 'event.ics');
        
        element.style.display = 'none';
        document.body.appendChild(element);
        
        element.click();
        
        document.body.removeChild(element);
    }
    
}

