import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginService } from '../../services';
import { AjaxqueryService } from '../../services';
import { ContactService } from 'app/services';
import { Contact } from 'app/models';

import { AccountService } from 'app/services';
import { Account } from 'app/models';
import { of } from 'rxjs';

import { DatePipe } from '@angular/common';
import { Mail } from 'app/main/mail/mail.model';
import { VCard, VCardEncoding, Address } from 'ngx-vcard';

import { MessagingService } from 'app/services';

import { ExportEventDialogComponent } from 'app/main/home/dialogs/exportevent/exportevent.component';
import { MatDialog } from '@angular/material/dialog';

declare var select: any;

export interface DialogData {
    password: string;
}

@Component({
    selector   : 'home',
    templateUrl: './home.component.html',
    styleUrls  : ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class HomeComponent implements OnInit
{
    rootHeaders: any;
    bookings: any;
    reminders: any;
    messages: Mail[];
    unreadMessageCount: string;
    snackBarRef: any;
    urlImage: string;
    fullLogin: boolean;

    articlesToShowCompact: any;
    // password: string;

    urlParameterAccountName: string;
    urlParameterDirectCode: string;

    contact: Contact;
    account: Account;

    orderHeaders: any[];
    orderSumAmount: number;
    ordersToProcess: number;
    totOrderSumAmount: number;
    totOrdersCount: number;

    supplierOrderHeaders: any[];
    supplierOrderSumAmount: number;
    supplierOrdersToProcess: number;
    totSupplierOrderSumAmount: number;
    totSupplierOrdersCount: number;

    confirmedCommissions: any[];
    thisMonthCommissionAmount: number;
    notConfirmedCommissions: any[];
    notConfirmedCommissionAmount: number;

    waitingSignDocuments: any[] = null;
    signedDocuments: any[] = null;

    private _unsubscribeAll: Subject<any>;

    public vCardEncoding: typeof VCardEncoding = VCardEncoding;
    public vCard: VCard = { version: '3.0', name: {  } };
    private vCardAddress: Address = { };

    vCardString: string;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _loginService: LoginService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationService: TranslateService,
        private _ajaxQuery: AjaxqueryService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _contactService: ContactService,
        private _accountService: AccountService,
        private _messaging: MessagingService,
        public _matDialog: MatDialog,
        private datePipe: DatePipe
        
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, italian);
    
        this._fuseTranslationService.use('it');
        
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }


    ngOnInit(): void
    {
        this._route.queryParams.subscribe(queryParams => {
            // console.log('parametri ' + JSON.stringify(queryParams)); // Your params called every change
            this.urlParameterAccountName = this._route.snapshot.queryParams['a'] || '';
            this.urlParameterDirectCode = this._route.snapshot.queryParams['dc'] || '';
            this.loadPage();     
        });
    }

    loadPage(): void 
    {
        if (this.urlParameterAccountName != null && this.urlParameterAccountName !== '' && 
            this.urlParameterDirectCode != null && this.urlParameterDirectCode !== ''){
            this.checkForDirectAccess();
        } else {
            this._accountService.checkSessionToken();
            this.drawHomeData();
        }
    }

    openCalendarLink(accountName: string, dtfrom: string, dtTo: string): void
    {
        const dialogRef = this._matDialog.open(ExportEventDialogComponent, {
            panelClass: 'export-event-dialog',
            width: '320px',
            data: { title: accountName, dateFrom: dtfrom, dateTo: dtTo }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });

    }

    openCalendarReminderLink(subject: string, parentName: string, parentlabel: string, parentValue: string, dtDay: string): void
    {
        if (this._contactService.isContactActive()){
            let text = '';
            
            if (subject != null && subject !== ''){
                text += subject;
            }
            if (parentName != null && parentName !== ''){
                text += ' ' + parentName;
            }
            if (parentlabel != null && parentlabel !== ''){
                text += ' ' + parentlabel;
            }
            if (parentValue != null && parentValue !== ''){
                text += ' ' + parentValue;
            }

            // dtDay 25/02/2020  --> 20201208/20201209
            const str = dtDay.split('/');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
    
            const dtStart = new Date(year, month, date);
            const dtEnd = new Date(dtStart.getTime() + (1000 * 60 * 60 * 24));

            const dtfrom = this.datePipe.transform(dtStart, 'yyyyMMdd');
            const dtTo = this.datePipe.transform(dtEnd, 'yyyyMMdd');

            const dialogRef = this._matDialog.open(ExportEventDialogComponent, {
                panelClass: 'export-event-dialog',
                width: '320px',
                data: { title: text, dateFrom: dtfrom, dateTo: dtTo }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
            });

        } 
    }

    openMailInbox(): any
    {
        if (this._contactService.isContactActive()){
            this._router.navigate(['/mail/inbox/'], { queryParamsHandling: 'merge' });
        }
    }

    openMail(kMail: string): any
    {
        if (this._contactService.isContactActive()){
            this._router.navigate(['/mail/inbox/' + kMail], { queryParamsHandling: 'merge' });
        }
    }

    openChoosePasswordDialog(): any
    {
        this._contactService.openChoosePasswordDialog();
    }

    drawHomeData(): any
    {
        this._ajaxQuery.getData4Home()
        .subscribe(data => {
            
            if (data != null && data.ERROR != null){
                this.promptAndGoToLogin();
            }

            if (data != null && data.FullLogin != null){
                // E' un utente che ha fatto tutto il processo di login
                this.fullLogin = true;
            } else {
                this.fullLogin = false;
            }

            if (data != null && data.Account){
                // Update observable
                this._accountService.updatedAccountSelection(data.Account);
            }

            if (data != null && data.ArticlesForContact != null){
                this.rootHeaders = data.ArticlesForContact;
            }

            if (data != null && data.CompactArticlesForContact != null){
                const compacts = data.CompactArticlesForContact;
                
                this.articlesToShowCompact = [];

                // Ciclo x estrarre kArtHeaders
                let prevKArtHeader = null;
                let subArray = [];
                for (const artRec of compacts){
                    const kArtHeader = artRec.K_ArticleHeader;
                    if (kArtHeader === prevKArtHeader){
                        subArray.push(artRec);
                    } else {
                        if (subArray.length > 0){
                            this.articlesToShowCompact.push(subArray);
                        }
                        prevKArtHeader = kArtHeader;
                        subArray = [];
                        subArray.push(artRec);
                    }

                }
                if (subArray.length > 0){
                    this.articlesToShowCompact.push(subArray);
                }

            }

            if (data != null && data.BookingsForContact != null){
                this.bookings = data.BookingsForContact;
                
            }

            if (data != null && data.ReminderForContact != null){
                this.reminders = data.ReminderForContact;
                
            }

            if (data != null && data.MessagesForContact != null){
                this.messages = data.MessagesForContact;
            } else {
                this.messages = [];
            }

            if (data != null && data.UnreadMessagesCount != null){
                this.unreadMessageCount = data.UnreadMessagesCount;
            }


            // Ordini cliente se cliente
            if (data.OrderHeaders != null && data.OrderHeaders.length > 0){
                this.orderHeaders = data.OrderHeaders;
                this.ordersToProcess = 0;
                this.orderSumAmount = 0;
                this.totOrderSumAmount = 0;
                this.totOrdersCount = 0;
                for (const order of this.orderHeaders) {
                    if (order.OrderState === 'PAID' || order.OrderState === 'WAITING_PAY_CASH'){
                        this.orderSumAmount += parseFloat(order.OH_TotalAmount);
                        this.ordersToProcess++;
                    }
                    this.totOrderSumAmount += parseFloat(order.OH_TotalAmount);
                    this.totOrdersCount++;
                }
                
            } else {
                this.orderHeaders = [];
                this.orderSumAmount = 0;
                this.ordersToProcess = 0;
                this.totOrderSumAmount = 0;
                this.totOrdersCount = 0;
            }
            
            // Ordini fornitore se fornitore
            if (data.SupplierOrderHeaders != null && data.SupplierOrderHeaders.length > 0){
                this.supplierOrderHeaders = data.SupplierOrderHeaders;
                this.supplierOrdersToProcess = 0;
                this.supplierOrderSumAmount = 0;
                this.totSupplierOrderSumAmount = 0;
                this.totSupplierOrdersCount = 0;
                for (const order of this.supplierOrderHeaders) {
                    if (order.OrderState === 'SUPPLIER_SENT'){
                        this.supplierOrderSumAmount += parseFloat(order.OH_TotalAmount);
                        this.supplierOrdersToProcess++;
                    }
                    this.totSupplierOrderSumAmount += parseFloat(order.OH_TotalAmount);
                    this.totSupplierOrdersCount++;
                }
                
            } else {
                this.supplierOrderHeaders = [];
                this.supplierOrderSumAmount = 0;
                this.supplierOrdersToProcess = 0;
                this.totSupplierOrderSumAmount = 0;
                this.totSupplierOrdersCount = 0;
            }

            
            // Commissioni se dipendete 
            // commissioni CONFERMATE
            this.thisMonthCommissionAmount = 0;
            const thisMonthLabel = this.datePipe.transform(new Date(), 'yyyy-MM');
            if (data.ConfirmedCommissions != null && data.ConfirmedCommissions.length > 0){
                this.confirmedCommissions = data.ConfirmedCommissions;
                for (const commission of this.confirmedCommissions) {
                    if (thisMonthLabel === commission.Monthz){
                        this.thisMonthCommissionAmount = parseFloat(commission.CommissionMonth);
                    }
                }
            } else {
                this.confirmedCommissions = [];
            }

            // commissioni non confermate
            this.notConfirmedCommissionAmount = 0;
            if (data.NotConfirmedCommissions != null && data.NotConfirmedCommissions.length > 0){
                this.notConfirmedCommissions = data.NotConfirmedCommissions;
                for (const commission of this.notConfirmedCommissions) {
                    this.notConfirmedCommissionAmount += parseFloat(commission.CommissionAmount);
                }
            } else {
                this.notConfirmedCommissions = [];
            }

        });

        this._ajaxQuery.getContactDetails()
        .subscribe(data => {
            if (data != null && data.Contact != null){
                this.contact = data.Contact;
                this._contactService.updatedContactSelection(data.Contact);
            } else {
                this.contact = null;
            }
        });

        this._ajaxQuery.getDocumentData4Home()
        .subscribe(data => {
            if (data != null && data.WaitingSign != null){
                this.waitingSignDocuments = data.WaitingSign;
            } else {
                this.waitingSignDocuments = null;
            }

            if (data != null && data.Signed != null){
                this.signedDocuments = data.Signed;
            } else {
                this.signedDocuments = null;
            }

        });

        this.account = new Account();
        this._accountService.data
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            // do what ever needs doing when data changes
            this.account = data;
            
            if (this.account != null && this.account.name != null){
                this.vCard.name.firstNames = this.account.name;
                this.vCard.name.lastNames = this.account.fiscalname;
                this.vCard.organization = this.account.fiscalname;

                this.vCard.telephone = [this.account.companyphone, this.account.phone];
                if (this.account.email != null){
                    this.vCard.email = [this.account.email];
                }

                if (this.account.address != null){
                    this.vCardAddress.street = this.account.address;
                    this.vCardAddress.postalCode = this.account.cap;
                    this.vCardAddress.locality = this.account.city;
                    this.vCardAddress.region = this.account.province;
                    this.vCardAddress.extendedAddress = this.account.address + ' ' + 
                                                        this.account.cap + ' ' +
                                                        this.account.city + ' ' +
                                                        this.account.province;

                    this.vCard.address = [this.vCardAddress];
                }

            }

        });

    }

    openDocumentToSign(kInstance: string): any
    {

        const url = "https://firmafacile.app/sign?ktmpli="+kInstance;
        window.open(url, "_blank");

    }

    openDocumentSigned(kInstance: string, kAccount: string, kContact: string, kChild: string){

        const url = "https://firmafacile.app/sign?ktmpli="+kInstance+"&ka="+kAccount+"&kk="+kContact+"&ch="+kChild;
        window.open(url, "_blank");
        
    }

    checkForDirectAccess(): any
    {
        this._loginService.checkDirectCode(this.urlParameterAccountName, this.urlParameterDirectCode)
        .subscribe(data => {

            // Imposto comunque i parametri per la visibilità dell'account
            if (data != null && data.Account != null){
                // Update observable
                this._accountService.updatedAccountSelection(data.Account);
            }

            if (data == null || data.SESSION == null){
                // Non c'è una sessione attiva, salto alla login
                this.promptAndGoToLogin();
            } else {
                this.drawHomeData();
            }
        });

    }

    promptAndGoToLogin(): any
    {
        this.snackBarRef = this._snackBar.open('Sessione non attiva, per favore accedi', 'OK', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
        this.snackBarRef.afterDismissed().subscribe(() => {
            this._messaging.logOut();
            // Salta a Login
        });
        this.snackBarRef.onAction().subscribe(() => {
            this._messaging.logOut();
            // Salta a Login
        });
    }

    openArticle(kArticle: string): void
    {
        if (this._contactService.isContactActive()){
            this._router.navigate(['/article'], { queryParams: { ka: kArticle }, queryParamsHandling: 'merge' });
        }
    }

    openDocuments(): void
    {
        if (this._contactService.isContactActive()){
            this._router.navigate(['/documents'], { queryParamsHandling: 'merge' });
        }
    }

    openShop(): void
    {
        this._router.navigate(['/shop'], { queryParamsHandling: 'merge' });
    }

    openOrder(kOrderHeader: string): any
    {
        this._router.navigate(['/order'], { queryParams: { koh: kOrderHeader }, queryParamsHandling: 'merge' });
    }

    openSupplierOrders(): any
    {
        this._router.navigate(['/orders'], { queryParams: { type: 'so' }, queryParamsHandling: 'merge' });
    }

    openMonthlyCommissions(yyyymm: string): any
    {
        const yyyymmClean = yyyymm.replace('-', '');
        this._router.navigate(['/commissions'], { queryParams: { yyyymm: yyyymmClean }, queryParamsHandling: 'merge' });
    }

    openCommissionDetails(kCommission: string): any
    {
        this._router.navigate(['/commission'], { queryParams: { kco: kCommission }, queryParamsHandling: 'merge' });
    }

    openUsma(): any
    {
        this._router.navigate(['/usma'], { queryParamsHandling: 'merge' });
    }

    openPresences(): any
    {
        this._router.navigate(['/presences'], { queryParamsHandling: 'merge' });
    }

    openVCard(): void 
    {

    }

    switchContact(kcontact: string): any
    {
        this._ajaxQuery.switchContact(kcontact)
        .subscribe(data => {
            
            if (data != null && data.OK === 'OK'){
                console.log('switchContact ' + this._router.url);
                if (!this._router.url.startsWith('/home')){
                    this._router.navigate(['/home']);
                } else {
                    window.location.reload();
                }

                
            }

        });
    }

}
