import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule } from '@fuse/components/demo/demo.module';

import { DocumentsComponent } from 'app/main/documents/documents.component';

import { FuseSidebarModule } from '@fuse/components';

const routes = [
    // Carded
    {
        path     : 'documents',
        component: DocumentsComponent
    }
];

@NgModule({
    declarations: [
        DocumentsComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        MatExpansionModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatListModule,
         
        FuseSidebarModule,
        FuseSharedModule,
        FuseDemoModule
    ],
    exports     : [
        DocumentsComponent,
    ]
})
export class DocumentsModule
{
}
