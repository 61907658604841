import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/';
import { NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router, ActivatedRoute } from '@angular/router';

import { Contact } from '../models/contact';
import { ChosePasswordDialogComponent } from 'app/main/home/dialogs/chosepassword/chosepassword.component';

import { AjaxqueryService } from 'app/services';

import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ContactService {

    private contact = new BehaviorSubject(new Contact());
    data = this.contact.asObservable();
    snackBarRef: any;

    constructor( 
        private _snackBar: MatSnackBar,
        private _route: ActivatedRoute,
        private _router: Router,
        private _ajaxQuery: AjaxqueryService,
        public _matDialog: MatDialog
        ) 
    { 

    }

    updatedContactSelection(data: Contact): any 
    {
        const prevContact = this.contact.getValue();
        this.contact.next(data);

        // Per evitare la prima chiamata allo startup dell'applicazione
        // Ma voglio le chiamate di check ad ogni cambio contatto
        if (prevContact != null && prevContact.master != null){
            this.isContactActive();
        }
    }
  
    isContactActive(): any
    {
        const currContact = this.contact.getValue();
        if (currContact != null && currContact.master != null && currContact.master.state === 'A'){
            return true;
        } else if (currContact != null && currContact.master != null && currContact.master.state !== 'A'){
            const currUrl = this._router.url;
            // if (!currUrl.startsWith('/home')){
            this.showActivateContactPopup();
            // }
            return false;
        } else {
            // fai la query lato server...
            this._ajaxQuery.getContactDetails()
            .subscribe(data => {
                if (data != null && data.Contact != null){
                    this.updatedContactSelection(data.Contact);
                } else {
                    
                }
            });
            return true;
        }
    }

    reloadContactData(): any
    {
        // fai la query lato server...
        this._ajaxQuery.getContactDetails()
        .subscribe(data => {
            if (data != null && data.Contact != null){
                this.updatedContactSelection(data.Contact);
            } else {
                
            }
        });
    }

    private showActivateContactPopup(): void
    {
        this.snackBarRef = this._snackBar.open('Per favore proteggi il tuo account per poter accedere a tutte le funzionalità.', 'OK', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
        this.snackBarRef.afterDismissed().subscribe(() => {
            // Non fare nulla
        });
        this.snackBarRef.onAction().subscribe(() => {
            this.openChoosePasswordDialog();
        });
    }

    openChoosePasswordDialog(): any
    {
        const dialogRef = this._matDialog.open(ChosePasswordDialogComponent, {
            panelClass: 'chose-password-dialog',
            // width: '250px',
            data: { email: this.contact.getValue().master.email }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

}



@NgModule({
    providers: [ /* DONT ADD THE SERVICE HERE */ ]
})
export class ContactModule {
        static forRoot(): any {
            return {
                ngModule: ContactModule,
                providers: [ ContactService ]
            };
        }
}
