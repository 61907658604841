import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginService } from '../../../services';
import { AccountService } from 'app/services';
import { Account } from 'app/models';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    urlParameterAccountName: string;
    urlParameterDirectCode: string;
    
    email: string;
    password: string;
    token: string;

    account: Account;
    hide: boolean;

    buttonLoading: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private route: ActivatedRoute,
        private router: Router,
        private _loginService: LoginService,
        private _accountService: AccountService,
        private _snackBar: MatSnackBar
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._fuseTranslationLoaderService.loadTranslations(english, italian);
         
        this._unsubscribeAll = new Subject();
        this.hide = true;
        this.buttonLoading = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        this.urlParameterAccountName = this.route.snapshot.queryParams['a'] || '';
        this.urlParameterDirectCode = this.route.snapshot.queryParams['dc'] || '';
        
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

        this.account = new Account();
        this._accountService.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                // do what ever needs doing when data changes
                this.account = data;            
            });
        
        if (this.urlParameterAccountName == null || this.urlParameterAccountName === ''){
            // In questo caso non so quale è l'account, controllo con chiamata a server
            // se per caso c'è qualcosa nei cookies (che da client qui non posso vedere)
            this._loginService.checkForCookieAccount()
            .subscribe(data => {
                if (data != null && data.PublicUrlName != null){
                    this.urlParameterAccountName = data.PublicUrlName;
                    this.checkForLoginCode();
                } else {
                    // Salta alla pagina di scelta ACCOUNTS
                    this.router.navigate(['/choose'], { queryParamsHandling: 'merge' });
                }
            });

        } else {
            // Ho il parametro relaltivo all'account su cui fare login
            this.checkForLoginCode();
        }

        


    }
    
    checkForLoginCode(): any
    {

        if (this.urlParameterAccountName != null && this.urlParameterAccountName !== '' &&
                this.urlParameterDirectCode != null && this.urlParameterDirectCode !== '') {
            this.checkDirectAccesCode();
        } else if (this.urlParameterAccountName != null && this.urlParameterAccountName !== ''){
            this.checkSessionToken();
        }
    }

    checkDirectAccesCode(): any {
        this._loginService.checkDirectCode(this.urlParameterAccountName, this.urlParameterDirectCode)
        .subscribe(data => {

            // Imposto comunque i parametri per la visibilità dell'account
            if (data != null && data.Account){
                // Update observable
                this._accountService.updatedAccountSelection(data.Account);
            }

            if (data != null && data.SESSION != null && data.SESSION === 'OK'){
                this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
            }
        });

    }

    checkSessionToken(): any {
        this._loginService.checkSessionToken(this.urlParameterAccountName)
        .subscribe(data => {

            // Imposto comunque i parametri per la visibilità dell'account
            if (data != null && data.Account){
                // Update observable
                this._accountService.updatedAccountSelection(data.Account);
            }

            if (data != null && data.SESSION != null && data.SESSION === 'OK'){
                this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
            }
        });

    }


    onSubmit(): void 
    {
        
        console.log('onSubmit');

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.buttonLoading = true;

        this.email = this.loginForm.controls.email.value.trim();
        this.password = this.loginForm.controls.password.value.trim();

        this._loginService.login(this.email, this.password)
                .subscribe(data => {
                    this.buttonLoading = false;
                    
                    console.log(JSON.stringify(data));
                    if (data != null && data.SESSION != null && data.SESSION === 'OK'){
                        
                        this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
                    } else {
                        let error = '';
                        if (data != null && data.ERROR != null){
                            error = data.ERROR;
                        }
                        this._snackBar.open('Si è verificato un errore in fase di autenticazione (' + error + ')', 'OK', {
                            duration: 4000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });

                    }

                });

    }

    goToRegister(): void
    {
        this.router.navigate(['/register'], { queryParamsHandling: 'merge' });
    }

    goToForgotPwd(): void
    {
        this.router.navigate(['/forgot-password'], { queryParamsHandling: 'merge' });
    }
}
