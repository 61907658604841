export class Document
{
    kdocument: string;
    title: string;
    description: string;
    descriptionHtml: string;
    tags: string;
    dtTarget: string;
    dtEnd: string;
    dtInsert: string;
    extLink1: string;
    extLink2: string;
    extLink3: string;
    colorHex: string;
    attachments: {
        kaccount: string,
        type: string,
        fileName: string,
        preview: string,
        url: string,
        size: string
    }[];


    constructor(document)
    {
        this.kdocument = document.kdocument;
        this.title = document.title;
        if (document.description != null && document.description !== ''){
            this.descriptionHtml = document.description.replace(/\n/g, '<br/>');
        } else {
            this.descriptionHtml = document.description;
        }
        this.tags = document.tags;
        this.dtTarget = document.dtTarget;
        this.dtEnd = document.dtEnd;
        this.dtInsert = document.dtInsert;
        this.extLink1 = document.extLink1;
        this.extLink2 = document.extLink2;
        this.extLink3 = document.extLink3;
        this.attachments = document.attachments;
        this.colorHex = document.colorHex;
    }

}
