import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, catchError, finalize, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { AccountService } from 'app/services';
import { Account } from 'app/models';

import { takeUntil } from 'rxjs/internal/operators';

import { AjaxqueryService } from 'app/services/ajaxquery.service';

@Component({
    selector     : 'commissions',
    templateUrl  : './commissions.component.html',
    styleUrls    : ['./commissions.component.scss'],
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CommissionsComponent implements OnInit, AfterViewInit
{
    dataSource: CommissionsDataSource | null;
    displayedColumns = ['OH_Number', 'DT_OrderDate', 'DT_Confirm', 'CommissionAmount'];

    account: Account;
    yyyymm: string;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    @ViewChild('input', {static: true}) 
    input: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationService: TranslateService,
        private _accountService: AccountService,
        private _route: ActivatedRoute,
        private _ajaxQueryService: AjaxqueryService,
        private _router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        
        this._fuseTranslationLoaderService.loadTranslations(english, italian);
        this._fuseTranslationService.use(this._fuseTranslationService.currentLang);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this._route.queryParams.subscribe(queryParams => {
            this.yyyymm = queryParams.yyyymm;    
            this._ngOnInit();     
        });
    }

    _ngOnInit(): void
    {
        this.yyyymm = this._route.snapshot.queryParams['yyyymm'];

        this.dataSource = new CommissionsDataSource(this._ajaxQueryService);
        
        let filter = sessionStorage.getItem('filter_commission_filter');
        if (filter == null){
            filter = '';
        }
        this.input.nativeElement.value = filter;
        let sortColumn = sessionStorage.getItem('filter_commission_sortColumn');
        if (sortColumn == null){
            sortColumn = 'DT_OrderDate';
        }
        let sortDirection = sessionStorage.getItem('filter_commission_sortDirection');
        if (sortDirection == null){
            sortDirection = 'desc';
        }
        const pageIndex = sessionStorage.getItem('filter_commission_pageIndex');
        let pageIndexNum = 0;
        if (pageIndex != null){
            pageIndexNum = parseInt(pageIndex, 10);
        }
        const pageSize = sessionStorage.getItem('filter_commission_pageSize');
        let pageSizeNum = 10;
        if (pageSize != null){
            pageSizeNum = parseInt(pageSize, 10);
        }
        this.dataSource.loadCommissions(this.yyyymm, filter, sortColumn, sortDirection, pageIndexNum, pageSizeNum);

        this.account = new Account();
        this._accountService.data
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            // do what ever needs doing when data changes
            this.account = data;

        });
    }

    ngAfterViewInit(): void 
    {
        
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.loadCommissionsPage();
                })
            )
            .subscribe();

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadCommissionsPage())
            )
            .subscribe();

    }

    openCommission(kCommission: string): any
    {
        this._router.navigate(['/commission'], { queryParams: { kco: kCommission }, queryParamsHandling: 'merge' });
    }

    loadCommissionsPage(): any
    {
        this.dataSource.loadCommissions(
            this.yyyymm,
            this.input.nativeElement.value,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }


}


export class CommissionsDataSource implements DataSource<any> {

    private ordersSubject = new BehaviorSubject<any[]>([]);
    private loadingOrders = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingOrders.asObservable();
    public resultSize = 100;
    public pageSize = 10;
    public pageIndex = 0;

    constructor(private _ajaxQueryService: AjaxqueryService) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.ordersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.ordersSubject.complete();
        this.ordersSubject.complete();
    }

    loadCommissions(yyyymm: string, filter: string, sortColumn: string , sortDirection: string , pageIndex: number , pageSize: number ): any
    {

        // salvo in sessionStorage i filtri
        sessionStorage.setItem('filter_commission_filter', filter);
        sessionStorage.setItem('filter_commission_sortColumn', sortColumn);
        sessionStorage.setItem('filter_commission_sortDirection', sortDirection);
        sessionStorage.setItem('filter_commission_pageIndex', '' + pageIndex);
        sessionStorage.setItem('filter_commission_pageSize', '' + pageSize);

        this.pageSize = pageSize;
        this.pageIndex = pageIndex;

        this.loadingOrders.next(true);
        this._ajaxQueryService.getCommissions(yyyymm, filter, sortColumn, sortDirection, pageIndex, pageSize)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingOrders.next(false))
        )
        .subscribe(data => {
            if (data != null && data.Commissions != null && data.Commissions.length > 0){
                this.resultSize = data.Commissions_Count;
                this.ordersSubject.next(data.Commissions);
            } else {
                this.resultSize = 0;
                this.ordersSubject.next([]);
            }
            const element = document.querySelector('#container-3') || window;
            element.scrollTo(0, 0);
 
        });
    }    
}


