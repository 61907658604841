import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from 'app/services';
import { Account } from 'app/models';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

import { fuseAnimations } from '@fuse/animations';
import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/main/home/dialogs/confirmdialog/confirmdialog.component';

import { MatDialog } from '@angular/material';

@Component({
    selector     : 'order',
    templateUrl  : './order.component.html',
    styleUrls    : ['./order.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class OrderComponent implements OnInit, OnDestroy
{
    kOrderHeader: string;
    order: any;
    payTransaction: any;
    billing: any;
    shipping: any;
    statusForm: FormGroup;

    account: Account;

    typeOrder: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationService: TranslateService,
        private _ajaxQueryService: AjaxqueryService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _matDialog: MatDialog,
        private _accountService: AccountService,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.order = {};
        this.account = new Account();

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._fuseTranslationLoaderService.loadTranslations(english, italian);
        this._fuseTranslationService.use(this._fuseTranslationService.currentLang);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._route.queryParams.subscribe(queryParams => {
            this.kOrderHeader = queryParams.koh;    
            this.resetVars();
            this.loadPage();     
        });

    }

    resetVars(): void
    {
        this.kOrderHeader = '';
        this.order = {};
        this.payTransaction = {};
        this.billing = {};

    }

    loadPage(): void
    {
        this.account = new Account();
        this._accountService.data
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            // do what ever needs doing when data changes
            this.account = data;

        });

        this.typeOrder = this._route.snapshot.queryParams['type'];

        this.kOrderHeader = this._route.snapshot.queryParams['koh'];
        this._ajaxQueryService.getOrderDetails(this.kOrderHeader)
        .subscribe(data => {

            if (data != null && data.Order != null){
                this.order = data.Order;
            }

            if (data != null && data.PayTransaction != null){
                this.payTransaction = data.PayTransaction;
            }

            if (data != null && data.Billing != null){
                this.billing = data.Billing;
            }

            if (data != null && data.Shipping != null){
                this.shipping = data.Shipping;
            }

        });

    }



    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update status
     */
   
    openProduct(kProduct: string): any
    {
        this._router.navigate(['/prod'], { queryParams: { kp: kProduct } });
    }

    goBackPage(): any 
    {
        this.typeOrder = this._route.snapshot.queryParams['type'];
        if (this.typeOrder != null && this.typeOrder !== ''){
            this._router.navigate(['/orders'], { queryParams: { tipe: this.typeOrder }, queryParamsHandling: 'merge' });
        } else {
            this._router.navigate(['/home']);
        }

    }

    setOrderSentBack(): any
    {
        if (this.order.OH_Type !== 'F'){
            return;
        }


        let title = 'Ordine spedito?';
        let message = 'Confermi di voler marcare l\'ordine come spedito?';
        let cancelText = 'NO';
        let confirmText = 'SI';

        if (this._fuseTranslationService.currentLang === 'en'){
            title = 'Order Sent?';
            message = 'Do you want to set the order as sent back?';
            cancelText = 'NO';
            confirmText = 'YES';
        }

        const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            width: '400px',
            data: { 
                title: title,
                message: message,
                cancelText: cancelText,
                confirmText: confirmText
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed ' + result);
            if (result === true){
                this._ajaxQueryService.setOrderSentBackFromSupplier(this.order.K_OrderHeader)
                .subscribe(data => {
                    this.loadPage();

                }, err => {
                    // ...
                });
            }
        });


    }


}
