export const locale = {
    lang: 'en',
    data: {
        'LOGIN': {
            'ACCOUNT': 'Login to your area',
            'EMAILORPHONE': 'Email',
            'EMAILREQUIRED': 'Please insert your Email',
            'SENDCODE': 'Send Code *'
        }
    }
};
