import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Observer } from 'rxjs';

@NgModule({
    providers: [ ]
})
@Injectable({ providedIn: 'root' })
export class AjaxqueryService {
    
    base64DefaultURL: any;

    constructor(
        private http: HttpClient, 
        private _router: Router,
        private _route: ActivatedRoute
    ){

    }

    // CHIAMATE AJAX X SESSION/DATA
    
    checkDirectCode(urlParameterAccountName: string, urlParameterDirectCode: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'a': urlParameterAccountName,
              'dc': urlParameterDirectCode
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/checkDirectCode`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    checkSessionToken(urlParameterAccountName: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'a': urlParameterAccountName
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/checkSessionToken`;
        return this.http.post<any>(url, null , httpOptions);
    }  

    getContactDetails(): any 
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getContactDetails`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    switchContact(newKContact: string): any 
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'newKContact': newKContact
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/switchContact`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    getData4Home(): any 
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getHomeData`;
        return this.http.post<any>(url, null , httpOptions);
    }    

    getDocumentData4Home(): any 
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getDocumentData4Home`;
        return this.http.post<any>(url, null , httpOptions);
    }    

    addPasswordToContact(email: string, password: string): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'email': email,
                'password': btoa(password)
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/addPasswordToContact`;
        return this.http.post<any>(url, null , httpOptions);
    }

    askChangePasswordForContact(): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/askChangePasswordForContact`;
        return this.http.post<any>(url, null , httpOptions);
    }

    getArticleDetails(kArticle: string): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'kArticle': kArticle
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getArticleDetails`;
        return this.http.post<any>(url, null , httpOptions);
    }

    getDocuments(): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getDocuments`;
        return this.http.post<any>(url, null , httpOptions);
    }

    sendMessage(messagebody: string, fileToSend: File): any 
    {
        const httpOptions = {
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/sendMessage`;

        const data: any = new FormData();
        if (fileToSend != null){
            data.append('file', fileToSend);
        }
        data.append('messagebody', messagebody);

        return this.http.post<any>(url, data, httpOptions);
    }    
    
    uploadAttachment(kArticle: string, fileToSend: File): any 
    {
        const httpOptions = {
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/uploadAttachment`;

        const data: any = new FormData();
        if (fileToSend != null){
            data.append('file', fileToSend);
        }
        data.append('kArticle', kArticle);

        return this.http.post<any>(url, data, httpOptions);
    }  



/*
 *  COMMISSIONI
 */

    getCommissionDetails(kCommission: string): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'kCommission': kCommission
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getCommissionDetails`;
        return this.http.post<any>(url, null , httpOptions);
    }

    getCommissions(yyyymm: string, text: string, sortColumn: string, sortDirection: string, pageIndex: number, pageSize: number): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'yyyymm': yyyymm,
                'text': encodeURIComponent(text),
                'sortColumn': encodeURIComponent(sortColumn),
                'sortDirection': encodeURIComponent(sortDirection),
                'pageIndex': '' + pageIndex,
                'pageSize': '' + pageSize
            }),
            withCredentials: true
        };

        const url = `https://myarea.cloud/wa4b/mya/getCommissionsForMonth`;
        return this.http.post<any>(url, null, httpOptions);
    }

/*
 *  ORDINI
 */

    getOrders(text: string, sortColumn: string, sortDirection: string, pageIndex: number, pageSize: number): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'text': encodeURIComponent(text),
                'sortColumn': encodeURIComponent(sortColumn),
                'sortDirection': encodeURIComponent(sortDirection),
                'pageIndex': '' + pageIndex,
                'pageSize': '' + pageSize
            }),
            withCredentials: true
        };

        const url = `https://myarea.cloud/wa4b/mya/getOrders`;
        return this.http.post<any>(url, null, httpOptions);
    }

    getSupplierOrders(text: string, sortColumn: string, sortDirection: string, pageIndex: number, pageSize: number): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'text': encodeURIComponent(text),
                'sortColumn': encodeURIComponent(sortColumn),
                'sortDirection': encodeURIComponent(sortDirection),
                'pageIndex': '' + pageIndex,
                'pageSize': '' + pageSize
            }),
            withCredentials: true
        };

        const url = `https://myarea.cloud/wa4b/mya/getSupplierOrders`;
        return this.http.post<any>(url, null, httpOptions);
    }

    getOrderDetails(kOrderHeader: string): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'kOrderHeader': kOrderHeader
            }),
            withCredentials: true
        };

        const url = `https://myarea.cloud/wa4b/mya/getOrderDetails`;
        return this.http.post<any>(url, null, httpOptions);
    }

    setOrderSentBackFromSupplier(kOrderHeader: string): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'kOrderHeader': kOrderHeader
            }),
            withCredentials: true
        };

        const url = `https://myarea.cloud/wa4b/mya/setOrderSentBackFromSupplier`;
        return this.http.post<any>(url, null, httpOptions);
    }


/*
 *  BASE 
 */

    registerFbaseNoty(kContact: string, token: string): any 
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'kContact': kContact,
                'token': token
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/registerFbaseNoty`;
        return this.http.post<any>(url, null, httpOptions);
    }

    _logOut(): any
    {
        const httpOptions = {
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/logOut`;
        this.http.post<any>(url, null, httpOptions)
            .subscribe(data => {
                let a = null;

                if (data != null && data.PublicUrlName != null){
                    a = data.PublicUrlName;
                } else {
                    // Provo a prendere 'a' dai parametri di URL
                    a = this._route.snapshot.queryParams['a'] || '';
                }

                if (a != null){
                    this._router.navigate(['/login'], { queryParams: { a: a } });
                } else {
                    this._router.navigate(['/login']);
                }

            });
        
    }


    private getBase64Image(img: HTMLImageElement): any 
    {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        this.base64DefaultURL = dataURL;
        // return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
        return dataURL;
    }
    public getBase64ImageFromURL(url: string): any 
    {
        return Observable.create((observer: Observer<string>) => {
            // create an image object
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            if (!img.complete) {
                // This will call another method that will create image from url
                img.onload = () => {
                    observer.next(this.getBase64Image(img));
                    observer.complete();
                };
                img.onerror = (err) => {
                    observer.error(err);
                };
          } else {
                observer.next(this.getBase64Image(img));
                observer.complete();
          }
        });
    }

    /*
    *  USMA
    */

    getUsmaRecords(targetDate: string): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'annoscolastico': targetDate
            }),
            withCredentials: true
        };

        const url = `https://myarea.cloud/wa4b/mya/getUsmaRecordsForContact`;
        return this.http.post<any>(url, null, httpOptions);
    }

    getUsmaPresences(yyyyMM: string): any
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'yyyyMM': yyyyMM
            }),
            withCredentials: true
        };

        const url = `https://myarea.cloud/wa4b/mya/getUsmaPresencesForContact`;
        return this.http.post<any>(url, null, httpOptions);
    }

}
