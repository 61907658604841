import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NgModule } from '@angular/core';

@NgModule({
    providers: [ CookieService ]
})
@Injectable({ providedIn: 'root' })
export class LoginService {
    
    constructor(private http: HttpClient, private cookie: CookieService) {
    }

    /*
    // Store last account access
    public getCookieAccount(): string {
        return this.cookie.get('myacurraccount');
    }
    public setCookieAccount(value: string): void {
        return this.cookie.set('myacurraccount', value);
    }
    public deleteCookieAccount(): void {
        return this.cookie.delete('myacurraccount');
    }

    // Store tokens for accounts
    public getCookieToken(prefixAccount: string): string {
        return this.cookie.get('myatoken_' + prefixAccount);
    }
    public setCookieToken(prefixAccount: string, token: string): void {
        return this.cookie.set('myatoken_' + prefixAccount, token);
    }
    public deleteCookieToken(prefixAccount: string): void {
        return this.cookie.delete('myatoken_' + prefixAccount);
    }
    */

    // CHIAMATE AJAX X LOGIN
    getAccountData(urlParameterAccountName: string): any{
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'a': urlParameterAccountName
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getAccountData`;
        return this.http.post<any>(url, null , httpOptions);
    }

    checkDirectCode(urlParameterAccountName: string, urlParameterDirectCode: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'a': urlParameterAccountName,
              'dc': urlParameterDirectCode
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/checkDirectCode`;
        return this.http.post<any>(url, null , httpOptions);
    }    
    
    checkSessionToken(urlParameterAccountName: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'a': urlParameterAccountName
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/checkSessionToken`;
        return this.http.post<any>(url, null , httpOptions);
    }  

    checkForCookieAccount(): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/checkForCookieAccount`;
        return this.http.post<any>(url, null , httpOptions);
    }

    getAllMYAAccounts(): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getAllMYAAccounts`;
        return this.http.post<any>(url, null , httpOptions);
    }


    login(email: string, password: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'mail': email,
              'password': btoa(password)
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/login`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    register(fname: string, lname: string, email: string, password: string, flpromo: string,
                cap: string, city: string, province: string, region: string, nation: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'mail': email,
              'password': btoa(password),
              'fname': fname,
              'lname': lname,
              'flpromo': flpromo,
              'cap': encodeURIComponent(cap),
              'city': encodeURIComponent(city),
              'province': encodeURIComponent(province),
              'region': encodeURIComponent(region),
              'nation': encodeURIComponent(nation)
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/register`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    activateAccount(activationCode: string, email: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'mail': email,
              'activationCode': activationCode
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/activateAccount`;
        return this.http.post<any>(url, null , httpOptions);
    }  

    forgotPassword(email: string, accountName: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'mail': email,
              'accountName': accountName
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/forgotPassword`;
        return this.http.post<any>(url, null , httpOptions);
    }   


    resetPassword(resetCode: string, email: string, password: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'mail': email,
              'password': btoa(password),
              'resetCode': resetCode,
              'email': email
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/resetPassword`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    checkValidResetPwdCode(resetCode: string): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'resetCode': resetCode
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/checkValidResetPasswordCode`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    
    getPrivacy(): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getPrivacyForAccount`;
        return this.http.post<any>(url, null , httpOptions);
    }   

    getConditions(): any {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            }),
            withCredentials: true
        };
        const url = `https://myarea.cloud/wa4b/mya/getConditionsForAccount`;
        return this.http.post<any>(url, null , httpOptions);
    }   

}
