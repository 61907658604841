import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, mergeMap } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AjaxqueryService } from 'app/services';
import { ContactService } from 'app/services';
import { Subject } from 'rxjs';

@Injectable()
export class MessagingService {

    private _unsubscribeAll: Subject<any>;

    currentMessage = new BehaviorSubject(null);

    constructor(
        private _ajaxQuery: AjaxqueryService,
        private _contactService: ContactService,
        private angularFireMessaging: AngularFireMessaging
    ) 
    {
        try {
            this.angularFireMessaging.messaging.subscribe(
                (_messaging) => {
                    _messaging.usePublicVapidKey('BBA2zvhJ0hSUjp2Q64qIl8KBWYvAWaSJFOcx2TmBx53f0hlrVA_Id_Tr8qa3eP4vGQXh4uYjMPo8uRo-zgKZ5B8');
                    _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                    _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
                }
            );
        } catch (Err){
            console.error('Error init Firebase Messaging subscribe');
        }
    }

    /**
     * update token in firebase database
     * 
     * @param userId userId as a key 
     * @param token token as a value
     */
    updateToken(kContact: string, token: string): any {
        // we can change this function to request our backend service
        this._ajaxQuery.registerFbaseNoty(kContact, token)
        .subscribe(() => {
            console.log('called for kContact ' + kContact);
            this.receiveMessage();
        });

    }

    /**
     * request permission for notification from firebase cloud messaging
     */
    requestPermission(kContact: string): any {
        this.angularFireMessaging.requestToken
        .subscribe((token) => {
            console.log('New Token ' + token + ' for contact ' + kContact);
            this.updateToken(kContact, token);
        }, (err) => {
            console.error('Unable to get permission to notify.', err);
        });
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage(): any {
        console.log('start listening messages ');
        this.angularFireMessaging.messages
        .subscribe((payload) => {
            alert('new message received PUSH ');
            console.log('new message received. ', payload);
            this.currentMessage.next(payload);
        });
    }

    logOut(): any {

        this.angularFireMessaging.getToken
        .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
        .subscribe(
            (token) => { console.log('Deleted!'); },
        );

        this._ajaxQuery._logOut();
    }

}
