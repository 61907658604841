export const locale = {
    lang: 'en',
    data: {
        'HOME': {
            
            'ORDERS_TITLE': 'Orders',
            'NO_ORDERS_TO_PROCESS': 'No Orders',
            'ORDER': 'Order',
            'LIST': 'LIST',

            'ADDTOCALENDAR': 'ADD TO CALENDAR'
        }
    }
};
