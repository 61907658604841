import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { HomeModule } from 'app/main/home/home.module';
import { LoginModule } from 'app/main/loginGroup/login/login.module';
import { RegisterModule } from 'app/main/loginGroup/register/register.module';
import { MailConfirmModule } from 'app/main/loginGroup/mail-confirm/mail-confirm.module';
import { AccountConfirmModule } from 'app/main/loginGroup/account-confirm/account-confirm.module';
import { ForgotPasswordModule } from 'app/main/loginGroup/forgot-password/forgot-password.module';
import { ResetPasswordModule } from 'app/main/loginGroup/reset-password/reset-password.module';
import { ArticleModule } from 'app/main/article/article.module';
import { DocumentsModule } from 'app/main/documents/documents.module';
import { ChooseModule } from 'app/main/loginGroup/choose/choose.module';
import { ConfirmDialogComponent } from 'app/main/home/dialogs/confirmdialog/confirmdialog.component';

import { ShopModule } from 'app/main/shop/shop.module';
import { ChosePasswordDialogComponent } from 'app/main/home/dialogs/chosepassword/chosepassword.component';
import { ExportEventDialogComponent } from 'app/main/home/dialogs/exportevent/exportevent.component';

import { ContactModule } from 'app/services/contact.service';
import { AccountModule } from 'app/services/account.service';

import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MessagingService } from 'app/services';

import { CommissionModule } from 'app/main/commission/commission/commission.module';
import { CommissionsModule } from 'app/main/commission/commissions/commissions.module';

import {OrderModule } from 'app/main/order/order/order.module';
import {OrdersModule } from 'app/main/order/orders/orders.module';

// https://www.npmjs.com/package/@angular-material-extensions/google-maps-autocomplete
import {AgmCoreModule} from '@agm/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';

import { environment } from '../environments/environment';

import { DatePipe } from '@angular/common';
import { UsmaModule } from './main/usma/usma/usma.module';
import { PresencesModule } from './main/usma/presences/presences.module';
import { UsmaDetailsDialogComponent } from './main/usma/usma/dialogs/usmadetailsdialog.component';

const appRoutes: Routes = [
    {
        path        : 'mail',
        loadChildren: './main/mail/mail.module#MailModule'
    },
    {
        path      : '**',
        redirectTo: 'login'
    }
    
];

@NgModule({
    declarations: [
        AppComponent,
        ChosePasswordDialogComponent,
        ConfirmDialogComponent,
        ExportEventDialogComponent,
        UsmaDetailsDialogComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        HomeModule,
        ArticleModule,
        LoginModule,
        RegisterModule,
        MailConfirmModule,
        AccountConfirmModule,
        ForgotPasswordModule,
        ResetPasswordModule,
        ContactModule.forRoot(),
        AccountModule.forRoot(),
        ChooseModule,
        ShopModule,
        CommissionModule,
        CommissionsModule,
        OrderModule,
        OrdersModule,
        UsmaModule,
        PresencesModule,
        
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDcxAvXGTferHTXx1bACnD91pi9cHcNLHQ',   // info@wa4business.com  -- WA4BusinessAdvanced Project
            language: 'it',
            libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule,

        DocumentsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule

    ],
    bootstrap   : [
        AppComponent
    ],
    exports     : [
        ChosePasswordDialogComponent,
        ExportEventDialogComponent,
        ConfirmDialogComponent,
        UsmaDetailsDialogComponent
    ],
    providers : [
        MessagingService,
        DatePipe
    ],
    entryComponents: [
        ChosePasswordDialogComponent,
        ExportEventDialogComponent,
        ConfirmDialogComponent,
        UsmaDetailsDialogComponent
    ]
})
export class AppModule
{
}
