import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';

import { ShopComponent } from './shop.component';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';


registerLocaleData(localeIt);

const routes = [
    {
        path     : 'shop',
        component: ShopComponent
    }
];

@NgModule({
    declarations: [
        ShopComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatDialogModule,
        MatToolbarModule,
        
        TranslateModule,

        FuseSharedModule
    ],
    exports     : [
        ShopComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'it' },
    ]
})

export class ShopModule
{
}
