export class Contact {
    kcontact: string;
    fname: string;
    lname: string;
    initials: string;
    phonenumber: string;
    email: string;
    state: string;
    rilevaPresenza: string;
    contactType: string;
    master: {
        kcontact: string;
        fname: string;
        lname: string;
        initials: string;
        phonenumber: string;
        email: string;
        state: string; // Occhio è sempre lo stato del master -- ovunque ;)
    };
    delegates: {
        kcontact: string;
        fname: string;
        lname: string;
        initials: string;
        phonenumber: string;
        email: string;
        state: string;
    }[];
}
