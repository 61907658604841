export const locale = {
    lang: 'it',
    data: {
        'COMMISSIONS': {
            'COMMISSIONS': 'Provvigioni',
            'ORDER_NUMBER': 'Numero Ordine',
            'ORDER_DATE': 'Data Ordine',
            'CONFIRM_DATE': 'Data Consegna',
            'TOTAL': 'Provvigione',
            'NOTE': 'Note',
            'STATE': 'Stato',
            'CUSTOMER': 'Cliente',

            'STATE_PAID': 'PAGATO',
            'STATE_WAITING_PAYMENT': 'ABBANDONATO',
            'STATE_WAITING_PAY_CASH': 'NON PAGATO',
            'STATE_CLOSE': 'CHIUSO',
            'STATE_DELETED': 'ELIMINATO',

            'ADDTOCALENDAR': 'AGGIUNGI A CALENDAR'
        }
    }
};
