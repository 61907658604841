import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { AjaxqueryService } from 'app/services';
import { MessagingService } from 'app/services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _snackBar: MatSnackBar,
        private _messaging: MessagingService,
        public _ajaxqueryService: AjaxqueryService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    executeFunction(item): any
    {
        if (item.function === 'logOut'){
            this._messaging.logOut();
        } else if (item.function === 'changePassword'){

            this._ajaxqueryService.askChangePasswordForContact()
                .subscribe(data => {
                    if (data != null && data.ERROR != null){
                        this._snackBar.open('Si è verificato un errore in fase di autenticazione (' + data.ERROR + ')', 'OK', {
                            duration: 4000,
                            horizontalPosition: 'center',
                            verticalPosition: 'bottom'
                        });
                    } else {
                        this._snackBar.open('Abbiamo inviato una email a ' + data.email + ' con il link per il cambio password.', 'OK', {
                            duration: 5000,
                            horizontalPosition: 'center',
                            verticalPosition: 'bottom'
                        });
                    }
                });



        }

    }


}
