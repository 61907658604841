import { FuseNavigation } from '@fuse/types';

import { AjaxqueryService } from '../services';

export const navigation: FuseNavigation[] = [
    /*{
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'login',
                title    : 'Login',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'user',
                url      : '/login',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'logincode',
                title    : 'Logincode',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'user',
                url      : '/logincode',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            }
        ]
    }*/


    {
        id       : 'home',
        title    : 'Home',
        // translate: 'NAV.SAMPLE.TITLE',
        type     : 'item',
        icon     : 'home',
        url      : '/home'
    },
    {
        id       : 'messages',
        title    : 'Messaggi',
        // translate: 'NAV.SAMPLE.TITLE',
        type     : 'item',
        icon     : 'question_answer',
        url      : '/mail'
    },
    {
        id       : 'storage',
        title    : 'Bacheca',
        // translate: 'NAV.SAMPLE.TITLE',
        type     : 'item',
        icon     : 'dashboard',
        url      : '/documents'
    },
    {
        id       : 'shop',
        title    : 'Vetrina',
        // translate: 'NAV.SAMPLE.TITLE',
        type     : 'item',
        icon     : 'shopping_cart',
        url      : '/shop'
    },
    {
        id       : 'pwdchange',
        title    : 'Cambia Password',
        // translate: 'NAV.SAMPLE.TITLE',
        type     : 'item',
        icon     : 'vpn_key',
        url      :  null,
        function : 'changePassword'
    },
    {
        id       : 'logout',
        title    : 'LogOut',
        // translate: '',
        type     : 'item',
        icon     : 'exit_to_app',
        url      : null,
        function : 'logOut'
        
    }

];
