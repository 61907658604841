export const locale = {
    lang: 'en',
    data: {
        'ORDER': {
            'ORDER': 'Order',
            'ORDER_DETAILS': 'Details',
            'ORDER_PRODUCTS': 'Products',

            'DELETE_ORDER': 'DELETE',
            'CLOSE_ORDER': 'CLOSE',
            'ORDER_STATUS': 'Status',
            'SUBTOTAL_AMT': 'SubTotal',
            'DISCOUNT_AMT': 'Discount',
            'SHIPPING_AMT': 'Shipping',
            'TOTAL_AMT': 'Total',
            'NAME': 'Name',
            'PHONE': 'Phone',
            'CUSTOMER': 'Customer',
            'PAYMENT': 'Payment',
            'SHIPPING_DATA': 'Shipping Data',
            'BILLING_DATA': 'Billing Data',

            'PAY_METHOD': 'Method',
            'PAY_AMOUNT': 'Amount',
            'PAY_DATE': 'Date',

            'OPEN_INCOME': 'SEE INCOME',
            'COUPON_CODE': 'Code',
            'COUPON_DESCR': 'Text',
            'COUPON_DISCOUNT': 'Discount',

            'LINE_TITLE': 'Product',
            'LINE_QUANTITY': 'Quantity',
            'LINE_PRICE': 'Price',

            'MARK_ORDER_SENT_BACK': 'SENT BACK',

            'SET_CARRIER_TRACKING': 'INSERT TRACKING',

            'STATE_PAID': 'PAID',
            'STATE_WAITING_PAYMENT': 'PROCESSING',
            'STATE_WAITING_PAY_CASH': 'NOT PAID',
            'STATE_CLOSE': 'CLOSED',
            'STATE_DELETED': 'DELETED',

            'STATE_SUPPLIER_DELIVERED_BACK': 'SENT BACK',
            'STATE_SUPPLIER_SENT': 'IN PROCESS',
            'STATE_SUPPLIER_RECEIVED': 'CLOSED',
            
            'PAYMETHOD_CASH': 'Contanti',
            'PAYMETHOD_DEBITCARD': 'POS o Bancomat',
            'PAYMETHOD_BANKDRAFT': 'Bonifico Bancario',
            'PAYMETHOD_STRIPE': 'Stripe',
            'PAYMETHOD_PAYPAL': 'PayPal',

            'female': 'female',
            'male': 'male',
            'unisex': 'unisex',

            'adult': 'adult',
            'all_ages': 'all ages',
            'teen': 'teen',
            'kids': 'kids',
            'infant': 'infant',
            'newborn': 'newborn',

            'SIZE': 'Size',
            'COLOR': 'Color',
            'AGE_GROUP': 'Age Group',
            'GENDER': 'Gender',
            'CUSTOM_OPTION': 'Free Variant',

            'SET_ORDER_PAID': 'PAID',
            'OPEN_CUSTOMER': 'SEE CUSTOMER',

            'ADDTOCALENDAR': 'ADD TO CALENDAR'
        }
    }
};
