import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginService } from '../../../services';
import { AccountService } from 'app/services';
import { Account } from 'app/models';

@Component({
    selector     : 'choose',
    templateUrl  : './choose.component.html',
    styleUrls    : ['./choose.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ChooseComponent implements OnInit
{
    loginForm: FormGroup;
    
    accounts: Account[];
    viewAccount: string;

    buttonLoading: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private route: ActivatedRoute,
        private router: Router,
        private _loginService: LoginService,
        private _accountService: AccountService,
        private _snackBar: MatSnackBar
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._fuseTranslationLoaderService.loadTranslations(english, italian);
         
        this._unsubscribeAll = new Subject();
        this.buttonLoading = false;
        this.accounts = [];
        this.viewAccount = null;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        this.loginForm = this._formBuilder.group({
            
        });

        this._loginService.getAllMYAAccounts()
        .subscribe(data => {
            if (data != null && data.Accounts != null){
                this.accounts = data.Accounts;
            }
        });

    }
    
    onSubmit(): void 
    {
        
        console.log('onSubmit');

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.buttonLoading = true;

        if (this.viewAccount != null && this.viewAccount !== ''){
            this.router.navigate(['/login'], { queryParams: { a: this.viewAccount }, queryParamsHandling: 'merge' });
        } else {
            this.buttonLoading = false;
        }


    }

}
