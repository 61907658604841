export const locale = {
    lang: 'en',
    data: {
        'COMMISSIONS': {
            'COMMISSIONS': 'Commissions',
            'ORDER_NUMBER': 'Order Number',
            'ORDER_DATE': 'Order Date',
            'CONFIRM_DATE': 'Confirm Data',
            'TOTAL': 'Commission',
            'NOTE': 'Note',
            'STATE': 'State',
            'CUSTOMER': 'Customer',

            'STATE_PAID': 'PAID',
            'STATE_WAITING_PAYMENT': 'ABANDONED CART',
            'STATE_WAITING_PAY_CASH': 'NOT PAID',
            'STATE_CLOSE': 'CLOSED',
            'STATE_DELETED': 'DELETED',

            'ADDTOCALENDAR': 'ADD TO CALENDAR'
        }
    }
};
