import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginService } from '../../../services';
import { AccountService } from 'app/services';
import { Account } from 'app/models';

import { MatDialog } from '@angular/material/dialog';

import {Location, Appearance, GermanAddress} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { ConfirmDialogComponent } from 'app/main/home/dialogs/confirmdialog/confirmdialog.component';

@Component({
    selector     : 'register',
    templateUrl  : './register.component.html',
    styleUrls    : ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy
{
    registerForm: FormGroup;
    urlParameterAccountName: string;
    urlImage: string;

    email: string;
    password: string;
    fname: string;
    lname: string;
    promotion: string;

    snackBarRef: any;

    account: Account;

    hide: boolean;
    buttonLoading: boolean;

    showAddress: boolean;
    public appearance = Appearance;
    public selectedAddress: PlaceResult;

    cap: string;
    city: string;
    province: string;
    region: string;
    nation: string;


    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private _loginService: LoginService,
        private _accountService: AccountService,
        public _matDialog: MatDialog,
        private _snackBar: MatSnackBar
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.hide = true;
        this.buttonLoading = false;
        this.showAddress = false;

        this.cap = null;
        this.city = null;
        this.province = null;
        this.region = null;
        this.nation = null;

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.urlParameterAccountName = this.route.snapshot.queryParams['a'] || '';
        
        this.registerForm = this._formBuilder.group({
            name           : ['', Validators.required],
            lname          : ['', Validators.required],
            email          : ['', [Validators.required, Validators.email]],
            password       : ['', [Validators.required, Validators.minLength(6)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
            terms          : ['', Validators.required],
            promotion      : ['']
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
            this.registerForm.get('passwordConfirm').updateValueAndValidity();
        });

        if (this.urlParameterAccountName != null && this.urlParameterAccountName !== ''){
            this._loginService.getAccountData(this.urlParameterAccountName)
            .subscribe(data => {
                if (data != null && data.Account){
                    // Update observable
                    this._accountService.updatedAccountSelection(data.Account);
                }
            });
        } else {
            this._loginService.checkForCookieAccount()
            .subscribe(data => {
                if (data != null && data.PublicUrlName != null){
                    this.urlParameterAccountName = data.PublicUrlName;
                    this._loginService.getAccountData(this.urlParameterAccountName)
                    .subscribe(dataz => {
                        if (dataz != null && dataz.Account){
                            // Update observable
                            this._accountService.updatedAccountSelection(dataz.Account);
                        }
                    });  
                } else {
                    // Salta alla pagina di scelta ACCOUNTS
                    this.router.navigate(['/choose'], { queryParamsHandling: 'merge' });
                }
            });
        }
            
        this._accountService.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                // do what ever needs doing when data changes
                this.account = data;
                
                if (this.account.kaccount == 'c50b68a5-5721-4751-a249-099e007b7d4b'){
                    this.showAddress = true;

                }

            });
            
    }


    onSubmit(): void {
        
        console.log('onSubmit');
    
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
    
        this.buttonLoading = true;
        this.email = this.registerForm.controls.email.value.trim();
        this.password = this.registerForm.controls.password.value.trim();
        this.fname = this.registerForm.controls.name.value;
        this.lname = this.registerForm.controls.lname.value;
        this.promotion = '' + this.registerForm.controls.promotion.value;

        let cap = '';
        let city = '';
        let province = '';
        let region = '';
        let nation = '';
        if (this.showAddress == true){
            cap = this.cap != null ? this.cap : '';
            city = this.city != null ? this.city : '';
            province = this.province != null ? this.province : '';
            region = this.region != null ? this.region : '';
            nation = this.nation != null ? this.nation : 'IT';            
        }


        this._loginService.register(this.fname, this.lname, this.email, this.password, this.promotion,
            cap, city, province, region, nation)
        .subscribe(data => {
            this.buttonLoading = false;
            console.log(JSON.stringify(data));
            if (data != null && data.email != null){
                
                this.router.navigate(['/mail-confirm'], { queryParams: { a: this.urlParameterAccountName, email: data.email }, queryParamsHandling: 'merge'  });

                /* // this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
                this.snackBarRef = this._snackBar.open('Abbiamo inviato la mail di conferma a ' + data.email, 'OK', {
                    duration: 5000,
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',

                });
                this.snackBarRef.afterDismissed().subscribe(() => {
                    this.router.navigate(['/mail-confirm'], { queryParams: { a: this.urlParameterAccountName, email: data.email }, queryParamsHandling: 'merge'  });
                });
                this.snackBarRef.onAction().subscribe(() => {
                    this.router.navigate(['/mail-confirm'], { queryParams: { a: this.urlParameterAccountName, email: data.email }, queryParamsHandling: 'merge'  });
                }); */

            } else {

                if (data != null && data.ERROR === 'ER_CONTACT_ALREADY_PRESENT'){
                    this.snackBarRef = this._snackBar.open('Utente già registrato! Accedi al sistema con Login', 'OK', {
                        duration: 4000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });

                    this.snackBarRef.onAction().subscribe(() => {
                        this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
                    });

                } else {
                    this._snackBar.open('Si è verificato un errore in fase di registrazione', 'OK', {
                        duration: 4000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });
                }

            }

        });
    
    }

    openPrivacy(): any {

        this._loginService.getPrivacy()
        .subscribe(data => {
            if (data != null && data.Privacy != null && data.Privacy != ''){
	    		if (data.IsExtLink == '1'){
	    			window.open(data.Privacy, '_blank');
	    		} else {
	    			
                    const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
                        panelClass: 'confirm-dialog',
                        width: '400px',
                        data: { 
                            title: 'Privacy',
                            message: data.Privacy,
                            cancelText: 'Chiudi',
                            confirmText: 'Ok'
                        }
                    });

	    		}
	    	} else if (data != null && data.AccountEmail != null && data.AccountEmail != ''){
	    		// Open Defaul Dialog Conditions
	    	    const htmlDefault = 'Il titolare del trattamento dati &egrave; <b>'+data.AccountName+'</b>.<br>'+
	    	    					'Per maggiori dettagli scrivi a <b>'+data.AccountEmail+'</b>.';
                
                const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
                    panelClass: 'confirm-dialog',
                    width: '400px',
                    data: { 
                        title: 'Privacy',
                        message: htmlDefault,
                        cancelText: 'Chiudi',
                        confirmText: 'Ok'
                    }
                });                    

	    	} else {
                // Apri il link di default
                window.open('https://www.iubenda.com/privacy-policy/28770175', '_blank');
	    	}

         }, err => {
            // Apri il link di default
            window.open('https://www.iubenda.com/privacy-policy/28770175', '_blank');
        });

    }


    openConditions(): any {

        this._loginService.getPrivacy()
        .subscribe(data => {
            if (data != null && data.Conditions != null && data.Conditions != ''){
	    		if (data.IsExtLink == '1'){
	    			window.open(data.Conditions, '_blank');
	    		} else {
                    const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
                        panelClass: 'confirm-dialog',
                        width: '400px',
                        data: { 
                            title: 'Termini & Condizioni',
                            message: data.Conditions,
                            cancelText: 'Chiudi',
                            confirmText: 'Ok'
                        }
                    });
	    		}
	    	} else if (data != null && data.AccountEmail != null && data.AccountEmail != ''){
	    		// Open Defaul Dialog Conditions
	    	    const htmlDefault = 'Per avere maggiori informazioni in merito alle condizioni contattare: <b>'+data.AccountName+'</b><br>'+
	    	    					'all\'indirizzo email <b>'+data.AccountEmail+'</b>.';
                
                const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
                    panelClass: 'confirm-dialog',
                    width: '400px',
                    data: { 
                        title: 'Termini & Condizioni',
                        message: htmlDefault,
                        cancelText: 'Chiudi',
                        confirmText: 'Ok'
                    }
                });                    

	    	} else {
	    	    const htmlDefault = 'Per avere maggiori informazioni in merito alle condizioni contattare il proprietario del sistema.';
                const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
                    panelClass: 'confirm-dialog',
                    width: '400px',
                    data: { 
                        title: 'Termini & Condizioni',
                        message: htmlDefault,
                        cancelText: 'Chiudi',
                        confirmText: 'Ok'
                    }
                });   
	    	}

         }, err => {
            const htmlDefault = 'Per avere maggiori informazioni in merito alle condizioni contattare il proprietario del sistema.';
            const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
                panelClass: 'confirm-dialog',
                width: '400px',
                data: { 
                    title: 'Termini & Condizioni',
                    message: htmlDefault,
                    cancelText: 'Chiudi',
                    confirmText: 'Ok'
                }
            });   
        });

    }


    onAutocompleteSelected(result: PlaceResult) {
        console.log('onAutocompleteSelected: ', result);

        this.cap = null;
        this.city = null;
        this.province = null;
        this.region = null;
        this.nation = null;

        const comps = result.address_components;
        for (const comp of comps){
            var types = comp.types;

            for (const type of types){
                if (type === 'administrative_area_level_3'){
                    this.city = comp.long_name;
                } else if (type === 'administrative_area_level_2'){
                    this.province = comp.short_name;
                } else if (type === 'administrative_area_level_1'){
                    this.region = comp.long_name;
                } else if (type === 'country'){
                    this.nation = comp.short_name;
                } else if (type === 'postal_code'){
                    this.cap = comp.long_name;
                }
            }
        }

        console.log(this.cap + ' ' + this.city + ' ' + this.province + ' ' + this.region + ' ' + this.nation);

    }


    onLocationSelected(location: Location) {
        console.log('onLocationSelected: ', location);

    }
    
     onGermanAddressMapped($event: GermanAddress) {
        console.log('onGermanAddressMapped', $event);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    goToLogin(): void
    {
        this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
    }


}






/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};
