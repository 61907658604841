import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LoginService } from '../../../services';
import { AccountService } from 'app/services';
import { Account } from 'app/models';
@Component({
    selector     : 'reset-password',
    templateUrl  : './reset-password.component.html',
    styleUrls    : ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy
{
    resetPasswordForm: FormGroup;

    emailToReset: string;
    resetPwdCode: string;

    urlParameterAccountName: string;
    urlImage: string;
    snackBarRef: any;

    password: string;
    passwordConfirm: string;

    account: Account;

    hide: boolean;
    buttonLoading: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService,
        private _accountService: AccountService,
        private _snackBar: MatSnackBar
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.hide = true;
        this.buttonLoading = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        this.urlParameterAccountName = this.route.snapshot.queryParams['a'] || '';
        this.emailToReset = this.route.snapshot.queryParams['e'] || '';
        this.resetPwdCode = this.route.snapshot.queryParams['rc'] || '';

        if (this.emailToReset == null || this.emailToReset === '' ||
            this.resetPwdCode == null || this.resetPwdCode === ''){
            this.snackBarRef = this._snackBar.open('Accesso alla pagina non corretto', 'OK', {
                duration: 4000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
            });
            this.snackBarRef.afterDismissed().subscribe(() => {
                this.goToLogin();
            });
            this.snackBarRef.onAction().subscribe(() => {
                this.goToLogin();
            });

        }

        // Controllo che il codice di reset sia ancora valido
        this._loginService.checkValidResetPwdCode(this.resetPwdCode)
            .subscribe(data => {
                if (data == null || data.OK == null || data.OK !== 'OK'){
                    this.snackBarRef = this._snackBar.open('Link di reset password scaduto o non valido. Per favore chiedine uno nuovo.', 'OK', {
                        duration: 4000,
                        horizontalPosition: 'center',
                        verticalPosition: 'top'
                    });
                    this.snackBarRef.afterDismissed().subscribe(() => {
                        this.goToForgotPassword();
                    });
                    this.snackBarRef.onAction().subscribe(() => {
                        this.goToForgotPassword();
                    });
                }
            });

        this.resetPasswordForm = this._formBuilder.group({
            // name           : ['', Validators.required],
            // email          : ['', [Validators.required, Validators.email]],
            password       : ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        if (this.urlParameterAccountName != null && this.urlParameterAccountName !== ''){
            this._loginService.getAccountData(this.urlParameterAccountName)
                .subscribe(data => {
                    if (data != null && data.Account){
                        // Update observable
                        this._accountService.updatedAccountSelection(data.Account);
                    }
                });
        }

        this._accountService.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                // do what ever needs doing when data changes
                this.account = data;            
            });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goToLogin(): void
    {
        this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
    }
    goToForgotPassword(): void
    {
        this.router.navigate(['/forgot-password'], { queryParamsHandling: 'merge' });
    }

    onSubmit(): void 
    {
        
        console.log('onSubmit');

        // stop here if form is invalid
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.buttonLoading = true;

        this.passwordConfirm = this.resetPasswordForm.controls.passwordConfirm.value;
        this.password = this.resetPasswordForm.controls.password.value;

        this._loginService.resetPassword(this.resetPwdCode, this.emailToReset, this.password)
                .subscribe(data => {
                    this.buttonLoading = false;
                    console.log(JSON.stringify(data));
                    if (data != null && data.OK != null && data.OK === 'OK'){
                        this.snackBarRef = this._snackBar.open('La password è stata reimpostata correttamente, torna alla pagina di LOGIN per accedere!', 'OK', {
                            duration: 6000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });

                        this.snackBarRef.afterDismissed().subscribe(() => {
                            this.goToLogin();
                        });
            
                        this.snackBarRef.onAction().subscribe(() => {
                            this.goToLogin();
                        });

                    } else {
                        this._snackBar.open('Si è verificato un errore in fase di impostazione password', 'OK', {
                            duration: 4000,
                            horizontalPosition: 'center',
                            verticalPosition: 'top'
                        });

                    }

                });

    }


}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};
