import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, catchError, finalize, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { AccountService } from 'app/services';
import { Account } from 'app/models';

import { takeUntil } from 'rxjs/internal/operators';

import { AjaxqueryService } from 'app/services/ajaxquery.service';
import { formatDate, DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material';

@Component({
    selector     : 'presences',
    templateUrl  : './presences.component.html',
    styleUrls    : ['./presences.component.scss'],
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PresencesComponent implements OnInit, AfterViewInit
{
    dataSource: PresencesDataSource | null;
    displayedColumns = ['Data', 'Presente', 'Pranzo', 'Merenda'];

    account: Account;

    date = new FormControl(moment());

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseTranslationService: TranslateService,
        private _accountService: AccountService,
        private _datePipe: DatePipe,
        private _ajaxQueryService: AjaxqueryService,
        private _router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        
        // this._fuseTranslationService.use(this._fuseTranslationService.currentLang);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new PresencesDataSource(this._ajaxQueryService, this._datePipe);
        
        this.loadPresencesPage();

        this.account = new Account();
        this._accountService.data
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
            // do what ever needs doing when data changes
            this.account = data;
            
        });
    }

    ngAfterViewInit(): void 
    {       

    }


    goPrevMonth()
    {
        let year = parseInt(formatDate(this.date.value, "yyyy", 'en-GB'));
        let month = parseInt(formatDate(this.date.value, "MM", 'en-GB'));

        if (month == 1){
            month = 12;
            year = year -1;
        } else {
            month--;
        }

        let month_str = ''+month;
        if (month <= 9){
            month_str = '0'+month;
        }

        this.date = new FormControl(moment(year+'-'+month_str+'-15'));
        this.loadPresencesPage();
    }

    goNextMonth()
    {
        let year = parseInt(formatDate(this.date.value, "yyyy", 'en-GB'));
        let month = parseInt(formatDate(this.date.value, "MM", 'en-GB'));

        if (month == 12){
            month = 1;
            year = year + 1;
        } else {
            month++;
        }

        let month_str = ''+month;
        if (month <= 9){
            month_str = '0'+month;
        }

        this.date = new FormControl(moment(year+'-'+month_str+'-15'));
        this.loadPresencesPage();
    }


    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }
    
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.date.setValue(ctrlValue);
        datepicker.close();

        this.loadPresencesPage();
    }


    loadPresencesPage(): any
    {
        const targetDate = formatDate(this.date.value, "yyyy-MM", 'en-GB');
        this.dataSource.loadPresences(targetDate);
    }


}



export class PresencesDataSource implements DataSource<any> {

    private usmaSubject = new BehaviorSubject<any[]>([]);
    private loadingUsma = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingUsma.asObservable();

    constructor(
        private _ajaxQueryService: AjaxqueryService,
        private datePipe: DatePipe
    ) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.usmaSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.usmaSubject.complete();
    }

    loadPresences(yyyymm: string ): any
    {

        this.loadingUsma.next(true);
        this._ajaxQueryService.getUsmaPresences(yyyymm)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingUsma.next(false))
        )
        .subscribe(data => {
            if (data != null && data.PresenceRecords != null && data.PresenceRecords.length > 0){
                
                // elaboro la data ordine per formattarla x client (TimeZoneOffset)
                const ordrs = [];
                for (const order of data.PresenceRecords) {
                    // order.DT_OrderDate_Form_date = this._utilService.parseDateTime(order.DT_OrderDate_Form, 'SERVER', order.ServerMinutesTimeZoneOffset);
                    
                    ordrs.push(order);
                }

                this.usmaSubject.next(ordrs);
            } else {
                this.usmaSubject.next([]);
            }
            const element = document.querySelector('#container-3') || window;
            element.scrollTo(0, 0);
 
        });
    }    
}


